import { Children } from 'react';
import PropTypes from 'prop-types';

import { legacyTheme } from '@prose-ui/legacy';
import { makeStyles } from 'legacyStyles';

import { Footer } from 'Blocks/Footer';

import { Header } from 'Containers/Header';

// BaseScene: a common wrapper all scenes

const useStyles = makeStyles(
  {
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: `calc(100vh - ${legacyTheme.props.navbarHeight})`,

      [legacyTheme.breakpoints.match('sm')]: {
        minHeight: legacyTheme.props.fullHeight,
      },
    },
    contentBanner: {
      // hidden banner, show only navbar
      minHeight: `calc(100vh - ${legacyTheme.props.headerHeight})`,
    },
    displayBlock: {
      display: 'block',
    },
    relativePosition: {
      position: 'relative',
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    justifyBetween: {
      justifyContent: 'space-between',
    },
    justifyStart: {
      justifyContent: 'flex-start',
    },
    feedbackPadding: {
      /**
       * Mobile
       * 12px = padding above the Review&Refine tag
       * 24px = height of the Review&Refine tag
       * 9px = padding below the Review&Refine tag
       */
      minHeight: `calc(100vh - ${legacyTheme.props.headerHeight})`,
      paddingTop: `calc(${legacyTheme.spacing.s12} + 40px + ${legacyTheme.spacing.s12})!important`,

      [legacyTheme.breakpoints.up('sm')]: {
        paddingTop: `calc(${legacyTheme.spacing.s24} + 24px + ${legacyTheme.spacing.s32})!important`,
      },
    },
    padding: {
      padding: legacyTheme.spacing.s40,

      [legacyTheme.breakpoints.match('sm')]: {
        padding: `${legacyTheme.spacing.s32} ${legacyTheme.spacing.s16}`,
      },
    },
    contentFixedCTA: {
      paddingBottom: 70,

      [legacyTheme.breakpoints.up('sm')]: {
        paddingBottom: 48,
      },
    },
    contentFixedTipAndCTA: {
      paddingBottom: 120,

      [legacyTheme.breakpoints.up('sm')]: {
        paddingBottom: 140,
      },
    },
    beigeBg: {
      background: legacyTheme.palette.backgrounds.beige,
    },
    noirBg: {
      background: legacyTheme.palette.backgrounds.noir,
    },
    earthBg: {
      background: legacyTheme.palette.backgrounds.earth,
    },
  },
  { name: 'LegacyBaseScene' }
);

const Body = () => null;

const BaseScene = ({
  background,
  banner,
  children,
  display,
  header,
  HeaderProps,
  feedbackPadding,
  fixedCTA,
  fixedTipAndCTA,
  footer,
  FooterProps,
  justifyContent,
  padding,
  relativePosition,
  ...props
}) => {
  const { classes, cx } = useStyles(undefined, props?.classes ? { props } : undefined);
  const contentClassName = cx(classes.content, {
    [classes.contentBanner]: banner,
    [classes.displayBlock]: display === 'block',
    [classes.justifyCenter]: justifyContent === 'center',
    [classes.justifyBetween]: justifyContent === 'space-between',
    [classes.justifyStart]: justifyContent === 'start',
    [classes.beigeBg]: background === 'beige',
    [classes.noirBg]: background === 'noir',
    [classes.earthBg]: background === 'earth',
    [classes.padding]: padding,
    [classes.contentFixedCTA]: fixedCTA,
    [classes.contentFixedTipAndCTA]: fixedTipAndCTA,
    [classes.feedbackPadding]: feedbackPadding,
    [classes.relativePosition]: relativePosition,
  });

  // vue-like slots
  const body = Children.toArray(children).find(el => el.type === Body);

  return (
    <main {...props}>
      {header && <Header banner={banner} showFooterLink={footer} {...HeaderProps} />}

      {body ? (
        <>{body.props.children}</>
      ) : (
        <section
          aria-atomic="true"
          aria-live="assertive"
          className={contentClassName}
          data-testid="maincontent"
          id="maincontent"
        >
          {children}
        </section>
      )}

      {footer && <Footer {...FooterProps} />}
    </main>
  );
};

BaseScene.Body = Body;

BaseScene.defaultProps = {
  background: 'none',
  banner: true,
  className: null,
  display: 'flex',
  feedbackPadding: false,
  fixedCTA: false,
  fixedTipAndCTA: false,
  footer: false,
  FooterProps: {},
  header: false,
  HeaderProps: {},
  justifyContent: 'center',
  padding: false,
  relativePosition: false,
  classes: null,
};

BaseScene.propTypes = {
  background: PropTypes.oneOf(['beige', 'noir', 'none', 'earth']),
  banner: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  display: PropTypes.oneOf(['flex', 'block']),
  feedbackPadding: PropTypes.bool,
  fixedCTA: PropTypes.bool,
  fixedTipAndCTA: PropTypes.bool,
  footer: PropTypes.bool,
  FooterProps: PropTypes.shape({}),
  header: PropTypes.bool,
  HeaderProps: PropTypes.shape({}),
  justifyContent: PropTypes.oneOf(['center', 'inherit', 'normal', 'space-between', 'start']),
  padding: PropTypes.bool,
  relativePosition: PropTypes.bool,
};

export default BaseScene;
