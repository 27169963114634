import { useState } from 'react';

import { Link } from 'react-router-dom';

import { styled } from '@prose-ui/legacy';

import { BasicModal } from 'Components/BasicModal';
import Typography from 'Components/Typography';

const terms = (
  <Typography color="grey" variant="p4">
    By checking this box you consent to receive promotional text messages from Prose to the number
    provided above. Texts may be sent using an auto dialer. Consent is not required as a condition
    of purchase. If you would like to stop receiving text messages, please log in to your Prose
    account and visit your communication settings in your Account Settings, or reply STOP to any
    Prose text message at any time. Std. carrier rates may apply. Visit our{' '}
    <Link target="_blank" to="/privacy">
      privacy policy
    </Link>{' '}
    for more information.
  </Typography>
);

export const labelWithToolTip = 'I’d like to receive SMS texts for promotions and special offers';
export const labelWithToolTipCheckoutOptimisation = 'Text me with news and offers';

const StyledBasicModal = styled(BasicModal)`
  height: auto;
  text-align: center;
`;

export const LabelWithModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  return (
    <>
      <StyledBasicModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div data-testid="modal-content-marketing-sms-terms">{terms}</div>
      </StyledBasicModal>
      I’d like to receive SMS texts for promotions and special offers (I agree to{' '}
      <span
        data-testid="modal-opener-marketing-sms-terms"
        onClick={openModal}
        onKeyPress={openModal}
        role="button"
        tabIndex={0}
      >
        <u>these&nbsp;terms</u>
      </span>
    </>
  );
};
