import every from 'lodash/fp/every';
import filter from 'lodash/fp/filter';
import first from 'lodash/fp/first';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import isEmpty from 'lodash/fp/isEmpty';
import last from 'lodash/fp/last';
import map from 'lodash/fp/map';
import maxBy from 'lodash/fp/maxBy';
import sortBy from 'lodash/fp/sortBy';
import values from 'lodash/fp/values';

import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { couponLabelFromOrigin } from 'constants/coupons';
import currencies from 'constants/currencies';
import { productsCategories } from 'constants/products';
import * as statuses from 'constants/statuses';

import { accessoriesProducts } from 'assets/content/accessoriesProducts';

import { createApiSelectors } from 'utils/reduxUtils';

const [getOrderData, , getOrderStatus] = createApiSelectors(get('checkout.order'));

export { getOrderData, getOrderStatus };

export const getOrderItems = createSelector(getOrderData, order => (order && order.items) || []);

export const orderHasSubscription = createSelector(getOrderData, get('is_subscription'));

const getCoupons = createSelector(
  getOrderData,
  flow(
    get('coupons'),
    map(({ type, label, order_applied_discount }) => ({
      property: type,
      label: isEmpty(label) ? couponLabelFromOrigin(type) : label,
      value: order_applied_discount,
    }))
  )
);

export const getOrderCreditCoupons = createSelector(getCoupons, filter({ property: 'credit' }));

export const getOrderOfferCoupons = createSelector(getCoupons, filter({ property: 'offer' }));

export const getOrderSubscriptionCoupons = createSelector(
  getCoupons,
  filter({ property: 'subscription' })
);

const getSortedDeliveryDates = createSelector(
  getOrderData,
  flow(
    getOr({}, 'shipping_estimate'),
    values,
    maxBy(estimate => dayjs(new Date(estimate.max)).format('YYYYMMDD')),
    values,
    map(dateString => dayjs(new Date(dateString))),
    sortBy(date => date.format('YYYYMMDD')),
    map(date => date.format('M/D'))
  )
);

export const getDeliveryBy = createSelector(getSortedDeliveryDates, sortedDeliveryDates => {
  if (!sortedDeliveryDates || sortedDeliveryDates.length === 0) {
    return '';
  }
  if (sortedDeliveryDates.length === 1) {
    return `You will receive your order by ${first(sortedDeliveryDates)}.`;
  }
  return `You will receive your order between ${first(sortedDeliveryDates)} and ${last(
    sortedDeliveryDates
  )}.`;
});

export const getCurrencyFromCheckoutOrder = createSelector(
  getOrderData,
  getOrderStatus,
  (orderData, orderStatus) => {
    if (orderData?.currency) {
      return orderData.currency;
    }
    if (orderStatus === statuses.SUCCESS) {
      Sentry.captureMessage('Currency not found in order data');
    }
    return currencies.USD;
  }
);

export const getConfirmationOrderCategory = createSelector(
  getOrderData,
  getOr(productsCategories.HAIRCARE, 'formulaset.categories[0]')
);

export const getHasOnlyAccessoriesInOrderItems = createSelector(
  getOrderItems,
  every(item => accessoriesProducts.includes(item.variant.slug))
);

export const getIsCustomProductsOrder = createSelector(getOrderItems, items =>
  items.some(item => item.variant.is_formula)
);
