import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { legacyTheme } from '@prose-ui/legacy';
import { withStyles } from 'legacyStyles';

import Button from 'Components/LegacyButton';
import Typography from 'Components/Typography';

import BaseScene from 'Scenes/LegacyBaseScene';

const styles = {
  content: {
    padding: 64,

    [legacyTheme.breakpoints.match('sm')]: {
      padding: 32,
    },
  },
};

const NotFound = ({ classes }) => {
  return (
    <BaseScene classes={{ content: classes.content }} footer header justifyContent="space-between">
      <div data-testid="not-found">
        <Typography align="center" color="noir" paragraph variant="h1r">
          Oops...
        </Typography>
        <Typography align="center" color="noir" variant="p1">
          Gosh we’re sorry, it seems something isn’t working as expected.
        </Typography>
      </div>

      <Link to="/">
        <Button variant="noir">Go to Homepage</Button>
      </Link>
    </BaseScene>
  );
};

NotFound.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(NotFound);
