import first from 'lodash/fp/first';
import getOr from 'lodash/fp/getOr';

import * as Sentry from '@sentry/nextjs';

import { CHECKOUT_NEW_ADDRESS_FIELD_VALUE } from 'Apps/Checkout/constants/checkoutInputValues';
import * as checkoutSteps from 'Apps/Checkout/constants/checkoutSteps';

import { isPaymentInvalid } from 'Services/HTTPError';
import * as OrderService from 'Services/OrderService';
import * as UserService from 'Services/UserService';

import addOriginParam from 'utils/addOriginParam';

import {
  loadAddresses,
  saveAccountDetails,
  saveContactAddress,
  saveShippingAddress,
  saveShippingAddressV2,
  validateAddress,
} from 'dux/checkoutAddresses/actions';
import { loadCart } from 'dux/checkoutCart/actions';
import { orderLoadSuccessFromPaymentSuccess } from 'dux/checkoutOrder/actions';
import { setPumpsPreferences } from 'dux/orders/actions';
import {
  trackAddShippingInfo,
  trackCartUpdated,
  trackCreateAddress,
  trackOrderSuccess,
  trackPurchaseCompleted,
} from 'dux/tracking/actions';
import { getCartV2Data, getCartV2IsFree, getNextStepFromStepPropV2 } from 'dux/cartV2/selectors';
import { getNextStepFromStepProp } from 'dux/checkout/selectors';
import {
  getAddressChoiceAfterValidation,
  getAddressesStatus,
} from 'dux/checkoutAddresses/selectors';
import {
  getCartData,
  getCartStatus,
  getIsFreeAndCartHasNoSubscription,
  shouldProposePumps,
} from 'dux/checkoutCart/selectors';
import { shouldShowAddressValidation } from 'dux/featureFlags/selectors';
import { createCartV2, patchCartV2 } from 'dux/cartV2/thunks';
// Relies on all other checkout sub-domain actions
import { CLEAR_CHECKOUT } from 'dux/checkout/actionTypes';
// Relies only on cart actions
import { SET_NEW_ADDRESS } from 'dux/checkoutAddresses/actionTypes';
import { updateCart } from 'dux/checkoutCart/thunks';
import { stripeActions } from 'dux/checkoutPayment/slice';
import { saveStripePayment } from 'dux/checkoutPayment/thunks';
import { fetchCouponsStatuses } from 'dux/couponsStatuses/thunks';
import { signupUser } from 'dux/signup/thunks';

/**
 * Internal store
 */

export const clearCheckoutState = () => ({
  type: CLEAR_CHECKOUT,
});

/**
 * FETCH (addresses) and POST (cart)
 */

export const loadCheckout = (params, checkoutCategory) => async (dispatch, getState) => {
  const state = getState();

  await Promise.all([
    getAddressesStatus(state) === 'idle' && dispatch(loadAddresses()),
    getCartStatus(state) === 'idle' && dispatch(loadCart(params, checkoutCategory)),
  ]);
};

/**
 * POST
 */

const finishCheckoutSavingPayment =
  ({ checkoutCategory, values, stripe, cardElement }) =>
  async (dispatch, getState) => {
    const state = getState();
    const cart = getCartData(state);
    const cardPubkey = values.cardPubkey === 'newCard' ? null : values.cardPubkey;
    const saveCard = getOr(false, 'saveCard')(values);

    try {
      if (cart.need_address && !cart.shipping_address) {
        return {
          status: 'failure',
          addressError: 'Cannot place order without a shipping address.',
        };
      }

      if (shouldProposePumps(state, { checkoutCategory })) {
        await dispatch(
          setPumpsPreferences({
            category: checkoutCategory,
            wantsPumps: values.wantsPumps ?? false,
          })
        );
      }

      // This change should be backward compatible, here we introduce logic only if new field is set
      if (cardPubkey) {
        dispatch(stripeActions.saveSelectedCardPubkey({ cardPubkey }));
        const order = await OrderService.postWithSavedPayment(cardPubkey);

        dispatch(orderLoadSuccessFromPaymentSuccess(order));
        dispatch(trackOrderSuccess(order));

        return { status: 'success', order };
      }

      const { token } = await dispatch(
        saveStripePayment({ nameOnCard: values.nameOnCard, stripe, cardElement })
      ).unwrap();

      const order = await OrderService.post(token.id, saveCard);

      dispatch(orderLoadSuccessFromPaymentSuccess(order));
      dispatch(trackOrderSuccess(order));

      return { status: 'success', order };
    } catch (error) {
      if (isPaymentInvalid(error)) {
        return { status: 'failure', cardError: error.detail };
      }
      return { status: 'failure' };
    }
  };

const finishCheckoutSavingPaymentV2 =
  ({ values, stripe, cardElement }) =>
  async (dispatch, getState) => {
    const state = getState();
    const cart = getCartV2Data(state);
    const cardPubkey = values.cardPubkey === 'newCard' ? null : values.cardPubkey;
    const saveCard = getOr(false, 'saveCard')(values);

    try {
      if (cart.need_address && !cart.shipping_address) {
        return {
          status: 'failure',
          addressError: 'Cannot place order without a shipping address.',
        };
      }

      // This change should be backward compatible, here we introduce logic only if new field is set
      if (cardPubkey) {
        dispatch(stripeActions.saveSelectedCardPubkey({ cardPubkey }));
        const order = await OrderService.postWithSavedPaymentV2(cardPubkey);

        dispatch(orderLoadSuccessFromPaymentSuccess(order));

        return { status: 'success', order };
      }

      const { token } = await dispatch(
        saveStripePayment({ nameOnCard: values.nameOnCard, stripe, cardElement })
      ).unwrap();

      const order = await OrderService.postV2(token.id, saveCard);

      dispatch(orderLoadSuccessFromPaymentSuccess(order));

      return { status: 'success', order };
    } catch (error) {
      if (isPaymentInvalid(error)) {
        return { status: 'failure', cardError: error.detail };
      }
      return { status: 'failure', errorDetail: error?.detail };
    }
  };

/**
 * POST
 */

const finishFreeOrderwWithNoSubscriptionCheckout =
  ({ checkoutCategory, values, actions }) =>
  async (dispatch, getState) => {
    const state = getState();

    if (shouldProposePumps(state, { checkoutCategory })) {
      await dispatch(
        setPumpsPreferences({
          category: checkoutCategory,
          wantsPumps: values.wantsPumps ?? false,
        })
      );
    }

    try {
      // Save shipping address if needed
      if (values.addressPubkey !== CHECKOUT_NEW_ADDRESS_FIELD_VALUE) {
        dispatch(updateCart({ shipping_address: values.addressPubkey }));
      }

      if (values.addressPubkey === CHECKOUT_NEW_ADDRESS_FIELD_VALUE) {
        const address = await UserService.createAddress({
          ...values.newAddress,
        });
        actions.setFieldValue?.('addressPubkey', address.pubkey);
        dispatch({
          type: SET_NEW_ADDRESS,
          data: address,
        });
        dispatch(trackCreateAddress(address));
        await dispatch(updateCart({ shipping_address: address.pubkey }));
        dispatch(trackAddShippingInfo());
        dispatch(trackCartUpdated());
      }
    } catch (error) {
      return { status: 'failure', addressError: error };
    }

    try {
      // This change should be backward compatible, here we introduce logic only if new field is set
      const order = await OrderService.postFreeOrder();

      dispatch(orderLoadSuccessFromPaymentSuccess(order));
      dispatch(trackOrderSuccess(order));

      return { status: 'success', order };
    } catch (error) {
      if (isPaymentInvalid(error)) {
        return { status: 'failure', cardError: error.detail };
      }
      return { status: 'failure' };
    }
  };

const finishFreeOrderwWithNoSubscriptionCheckoutV2 =
  ({ values, actions }) =>
  async dispatch => {
    try {
      // Save shipping address if needed
      if (values.addressPubkey !== CHECKOUT_NEW_ADDRESS_FIELD_VALUE) {
        dispatch(patchCartV2({ shipping_address: values.addressPubkey }));
      }

      if (values.addressPubkey === CHECKOUT_NEW_ADDRESS_FIELD_VALUE) {
        const address = await UserService.createAddress({
          ...values.newAddress,
        });
        actions.setFieldValue?.('addressPubkey', address.pubkey);
        dispatch({
          type: SET_NEW_ADDRESS,
          data: address,
        });
        await dispatch(patchCartV2({ shipping_address: address.pubkey }));
      }
    } catch (error) {
      return { status: 'failure', addressError: error };
    }

    try {
      // This change should be backward compatible, here we introduce logic only if new field is set
      const order = await OrderService.postFreeOrderV2();

      dispatch(orderLoadSuccessFromPaymentSuccess(order));

      return { status: 'success', order };
    } catch (error) {
      if (isPaymentInvalid(error)) {
        return { status: 'failure', cardError: error.detail };
      }
      return { status: 'failure' };
    }
  };

/**
 * PATCH (contact) and POST (payment + finish Checkout)
 */
const areRequiredAddressFieldsComplete = address =>
  Boolean(address?.address1) &&
  Boolean(address?.city) &&
  Boolean(address?.country) &&
  Boolean(address?.state) &&
  Boolean(address?.zipcode);

export const saveCheckoutStep = params => async (dispatch, getState) => {
  const state = getState();
  const isFreeAndCartHasNoSubscription = getIsFreeAndCartHasNoSubscription(state);
  const shoudValidateAddress =
    shouldShowAddressValidation(state) &&
    areRequiredAddressFieldsComplete(params?.values?.newAddress) &&
    !getAddressChoiceAfterValidation(state);

  switch (params.step) {
    case 'account-details':
      return dispatch(saveAccountDetails(params));
    case 'shipping-address':
      if (shoudValidateAddress) {
        const { status, addressError = '' } = await dispatch(validateAddress(params));
        if (status === 'failure') return { status, addressError };
      }
      if (isFreeAndCartHasNoSubscription) {
        return dispatch(finishFreeOrderwWithNoSubscriptionCheckout(params));
      }
      return dispatch(saveShippingAddress(params));
    case 'payment':
      return dispatch(finishCheckoutSavingPayment(params));
    default:
      return null;
  }
};

const saveCreateAccount = params => async (dispatch, getState) => {
  const state = getState();
  const cartV2Data = getCartV2Data(state);
  const response = await dispatch(
    signupUser({
      user: {
        has_accepted_terms: true,
        last_name: params.values.account.lastName,
        username: params.values.account.email,
        first_name: params.values.account.firstName,
        phone: params.values.account.phone,
        text_optin: params.values.textOptin,
        text_marketing_optin: params.values.textMarketingOptin,
      },
    })
  ).unwrap();
  if (response) {
    await dispatch(createCartV2({ cartitems: cartV2Data.cartitems }));
    return { status: 'success' };
  }
  return { status: 'failure', magicLinkSent: true };
};

export const saveCheckoutStepV2 = params => async (dispatch, getState) => {
  const state = getState();
  const isFree = getCartV2IsFree(state);
  const shoudValidateAddress =
    shouldShowAddressValidation(state) &&
    areRequiredAddressFieldsComplete(params?.values?.newAddress) &&
    !getAddressChoiceAfterValidation(state);

  switch (params.step) {
    case 'create-account':
      return dispatch(saveCreateAccount(params));
    case 'account-details':
      return dispatch(saveAccountDetails(params));
    case 'shipping-address':
      if (shoudValidateAddress) {
        const { status, addressError = '' } = await dispatch(validateAddress(params));
        if (status === 'failure') return { status, addressError };
      }
      if (isFree) {
        return dispatch(finishFreeOrderwWithNoSubscriptionCheckoutV2(params));
      }
      return dispatch(saveShippingAddressV2(params));
    case 'payment':
      return dispatch(finishCheckoutSavingPaymentV2(params));
    default:
      return null;
  }
};

// Newer actions used for the new checkout flow, logic is 1-1 copy of legacy
export const createStepByStepCheckoutSubmit =
  // From react props


    ({ checkoutCategory, step, stripe, params, cardElement, addressValidation }) =>
    // From formik callback
    (values, actions) =>
    // From redux thunk
    async (dispatch, getState) => {
      const state = getState();
      const { status, order, cardError, addressError, userError } = await dispatch(
        saveCheckoutStep({
          checkoutCategory,
          values,
          step,
          stripe,
          cardElement,
          addressValidation,
          actions,
        })
      );

      const nextStep = getNextStepFromStepProp(state, { step });
      const withOrigin = addOriginParam(params);

      let nextRoute;
      actions.setSubmitting(false);
      if (status === 'success') {
        if (nextStep === checkoutSteps.SUCCESS) {
          dispatch(fetchCouponsStatuses());
          dispatch(
            trackPurchaseCompleted({
              express_checkout: false,
              payment_location: 'cart',
              payment_type: 'Credit Card',
            })
          );
          nextRoute = `/checkout/success?order-pubkey=${order.pubkey}`;
        } else {
          nextRoute = withOrigin(`/checkout/${checkoutCategory}/${nextStep}`);
        }
      }

      if (cardError) {
        actions.setFieldValue('cardError', cardError);
        nextRoute = `/checkout/${checkoutCategory}/${checkoutSteps.PAYMENT}`;
      }

      if (addressError) {
        if (addressError?.extra?.zipcode?.length) {
          actions.setFieldError('newAddress.zipcode', first(addressError?.extra?.zipcode));
        }
        Sentry.captureMessage(addressError);

        nextRoute = `/checkout/${checkoutCategory}/${checkoutSteps.SHIPPING_ADDRESS}`;
      }

      if (userError) {
        if (userError?.extra?.username?.length) {
          actions.setFieldError('account.email', first(userError?.extra?.username));
        }
        Sentry.captureMessage(userError);
        nextRoute = `/checkout/${checkoutCategory}/${checkoutSteps.ACCOUNT_DETAILS}`;
      }
      // options isn't used here, but as long as we don't have ts better to explicitely make it appear
      return { nextRoute, options: {} };
    };

export const createStepByStepCheckoutSubmitV2 =
  ({ checkoutCategory, step, stripe, params, cardElement }) =>
  // From formik callback
  (values, actions, setError) =>
  // From redux thunk
  async (dispatch, getState) => {
    const state = getState();
    const { status, order, cardError, addressError, userError, magicLinkSent, errorDetail } =
      await dispatch(
        saveCheckoutStepV2({
          values,
          step,
          stripe,
          cardElement,
          actions,
        })
      );

    const nextStep = getNextStepFromStepPropV2(state, { step });
    const withOrigin = addOriginParam(params);

    let nextRoute;
    actions.setSubmitting(false);
    if (status === 'success') {
      if (nextStep === 'success') {
        dispatch(
          trackPurchaseCompleted({
            express_checkout: false,
            payment_location: 'cart',
            payment_type: 'Credit Card',
          })
        );
        nextRoute = `/checkout/success?order-pubkey=${order.pubkey}`;
      } else {
        nextRoute = withOrigin(`/checkout/${checkoutCategory}/${nextStep}`);
      }
    }

    if (magicLinkSent) {
      nextRoute = `/signin?magic-link-sent=true`;
    }

    if (cardError) {
      actions.setFieldValue('cardError', cardError);
      nextRoute = `/checkout/${checkoutCategory}/payment`;
    }

    if (addressError) {
      if (addressError?.extra?.zipcode?.length) {
        actions.setFieldError('newAddress.zipcode', first(addressError?.extra?.zipcode));
      }
      Sentry.captureMessage(addressError);
      const shippingAddressStep = 'shipping-address';
      nextRoute = `/checkout/${checkoutCategory}/${shippingAddressStep}`;
    }

    if (userError) {
      if (userError?.extra?.username?.length) {
        actions.setFieldError('account.email', first(userError?.extra?.username));
      }
      Sentry.captureMessage(userError);
      const userDetailsStep = 'account-details';
      nextRoute = `/checkout/${checkoutCategory}/${userDetailsStep}`;
    }

    if (errorDetail) {
      setError(errorDetail);
    }
    // options isn't used here, but as long as we don't have ts better to explicitely make it appear
    return { nextRoute, options: {} };
  };

export const expressCheckoutSubmit =
  ({ payerPhone, paymentType, shippingAddress, shippingOption, token }) =>
  async (dispatch, getState) => {
    const state = getState();
    const isFreeAndCartHasNoSubscription = getIsFreeAndCartHasNoSubscription(state);

    try {
      const { addressError } = await dispatch(
        saveContactAddress({
          values: {
            addressPubkey: CHECKOUT_NEW_ADDRESS_FIELD_VALUE,
            phone: (payerPhone || shippingAddress?.phone)?.replace(/\s/g, ''),
            newAddress: {
              address1: shippingAddress?.addressLine?.[0],
              city: shippingAddress?.city,
              label: shippingOption?.label,
              name: shippingAddress?.recipient,
              state: shippingAddress?.region,
              zipcode: shippingAddress?.postalCode,
            },
          },
        })
      );

      if (addressError) {
        Sentry.captureMessage(addressError);
        return { status: 'failure' };
      }

      dispatch(trackAddShippingInfo());

      const order = isFreeAndCartHasNoSubscription
        ? await OrderService.postFreeOrder()
        : await OrderService.post(token?.id, false);

      dispatch(orderLoadSuccessFromPaymentSuccess(order));
      dispatch(trackOrderSuccess(order));
      dispatch(
        trackPurchaseCompleted({
          express_checkout: true,
          payment_location: 'cart',
          payment_type: paymentType,
        })
      );

      return { status: 'success', order };
    } catch (error) {
      return isPaymentInvalid(error)
        ? { status: 'failure', cardError: error.detail }
        : { status: 'failure' };
    }
  };

export const expressCheckoutSubmitV2 =
  ({ payerPhone, paymentType, shippingAddress, shippingOption, token }) =>
  async (dispatch, getState) => {
    const state = getState();
    const cartV2IsFree = getCartV2IsFree(state);

    try {
      const { addressError } = await dispatch(
        saveContactAddress({
          values: {
            addressPubkey: CHECKOUT_NEW_ADDRESS_FIELD_VALUE,
            phone: (payerPhone || shippingAddress?.phone)?.replace(/\s/g, ''),
            newAddress: {
              address1: shippingAddress?.addressLine?.[0],
              city: shippingAddress?.city,
              label: shippingOption?.label,
              name: shippingAddress?.recipient,
              state: shippingAddress?.region,
              zipcode: shippingAddress?.postalCode,
            },
          },
        })
      );

      if (addressError) {
        Sentry.captureMessage(addressError);
        return { status: 'failure' };
      }

      const order = cartV2IsFree
        ? await OrderService.postFreeOrderV2()
        : await OrderService.postV2(token?.id, false);

      dispatch(orderLoadSuccessFromPaymentSuccess(order));
      dispatch(
        trackPurchaseCompleted({
          express_checkout: true,
          payment_location: 'cart',
          payment_type: paymentType,
        })
      );

      return { status: 'success', order };
    } catch (error) {
      return isPaymentInvalid(error)
        ? { status: 'failure', cardError: error.detail }
        : { status: 'failure' };
    }
  };
