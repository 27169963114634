import PropTypes from 'prop-types';

import { legacyTheme } from '@prose-ui/legacy';
import { Field } from 'formik';
import { withStyles } from 'legacyStyles';

import Checkbox from 'Components/Checkbox';

const StyledCheckbox = withStyles({
  root: {
    maxWidth: 460,
    '&:not(:last-child)': {
      marginBottom: legacyTheme.spacing.s16,
    },
  },
})(Checkbox);

const TextOptinField = ({
  content,
  dataPrefix,
  displayMode,
  fieldName,
  isEditable,
  color,
  labelVariant,
}) => (
  <Field name={fieldName} type="checkbox">
    {({ field }) => (
      <StyledCheckbox
        dataPrefix={dataPrefix}
        disabled={!isEditable}
        displayMode={displayMode}
        id={fieldName}
        label={content}
        labelColor={color}
        labelVariant={labelVariant}
        name={fieldName}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
      />
    )}
  </Field>
);

TextOptinField.defaultProps = {
  displayMode: false,
  isEditable: false,
  color: 'grey',
  labelVariant: 'p3',
};

TextOptinField.propTypes = {
  content: PropTypes.node.isRequired,
  dataPrefix: PropTypes.string.isRequired,
  displayMode: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  color: PropTypes.oneOf(['grey', 'noirDark']),
  labelVariant: PropTypes.string,
};

export default TextOptinField;
