import currencies from 'constants/currencies';

import imageWithoutGiftCAD from 'assets/images/promotionalModals/trial-offer-skincare-minis-hc-focused.jpg';
import imageWithoutGiftUS from 'assets/images/promotionalModals/trial-offer-skincare-minis-hc-focused.jpg';

const tracking = {
  modalClosing: 'Landing - SK Minis + HC trial Popup - Closed',
  modalOpening: 'Landing - SK Minis + HC trial Popup - Viewed',
  modalOpening2sec: 'Landing - SK Minis + HC trial Popup - 2 Sec Viewed',
};

const content = {
  banner: 'Get 50% off haircare or try skincare for free',
  popUpModal: {
    [currencies.USD]: {
      tracking,
      image: imageWithoutGiftUS,
      headerTitle: 'EXCLUSIVE TRIAL OFFER',
      title: 'Unlock 50% off when you subscribe to haircare',
      description: 'Plus, get a free Skincare Starter Set when you subscribe to skincare.',
      cta: {
        ctaLabel: 'unlock the deal',
      },
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order. Get your Skincare Starter
          Set ($65 value) when you subscribe to Cleanser, Serum, and Moisturizer. Subsequent
          subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and
          Skincare. Offers subject to expire and valid only while supplies last. Cannot be applied
          to previous orders or non-subscribed items. Not valid for accessories or digital gift
          cards. Exceptions may apply. For full details on our return policy,{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            click here
          </a>
          .
        </>
      ),
    },
    [currencies.CAD]: {
      tracking,
      image: imageWithoutGiftCAD,
      headerTitle: 'EXCLUSIVE TRIAL OFFER',
      title: 'Unlock 50% off when you subscribe to haircare',
      description: 'Plus, get a free Skincare Starter Set when you subscribe to skincare.',
      cta: {
        ctaLabel: 'unlock the deal',
      },
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order. Get your Skincare Starter
          Set when you subscribe to Cleanser, Serum, and Moisturizer. Subsequent subscription orders
          will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offers
          subject to expire and valid only while supplies last. Cannot be applied to previous orders
          or non-subscribed items. Not valid for accessories or digital gift cards. Exceptions may
          apply. For full details on our return policy,{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            click here
          </a>
          .
        </>
      ),
    },
  },
  bottomModal: {
    [currencies.USD]: {
      title: 'EXCLUSIVE TRIAL OFFER',
      description: (
        <>
          Unlock <b>50% off</b> when you subscribe to haircare. Plus, get a{' '}
          <b>free Skincare Starter Set</b> when you subscribe to skincare.
        </>
      ),
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order. Get your Skincare Starter
          Set ($65 value) when you subscribe to Cleanser, Serum, and Moisturizer. Subsequent
          subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and
          Skincare. Offers subject to expire and valid only while supplies last. Cannot be applied
          to previous orders or non-subscribed items. Not valid for accessories or digital gift
          cards. Exceptions may apply. For full details on our return policy,{' '}
          <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
            click here
          </a>
          .
        </>
      ),
    },
    [currencies.CAD]: {
      title: 'EXCLUSIVE TRIAL OFFER',
      description: (
        <>
          Unlock <b>50% off</b> when you subscribe to haircare.
          <br />
          Plus, get a <b>free Skincare Starter Set</b> when you subscribe to skincare.
        </>
      ),
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order. Get your Skincare Starter
          Set when you subscribe to Cleanser, Serum, and Moisturizer. Subsequent subscription orders
          will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offers
          subject to expire and valid only while supplies last. Cannot be applied to previous orders
          or non-subscribed items. Not valid for accessories or digital gift cards. Exceptions may
          apply. For full details on our return policy,{' '}
          <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
            click here.
          </a>
          .
        </>
      ),
    },
  },
} as const;

export default content;
