import type { ComponentProps } from 'react';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

import { BasicModal } from 'Components/BasicModal';
import Typography from 'Components/Typography';

import { genitive } from 'utils/textUtils';

const Label = styled(Typography)`
  font-size: ${theme.typography.fontSize.sm};
  margin-bottom: ${theme.spacing['4x']};
`;

const InciList = styled(Typography)`
  color: ${theme.colors.neutral[800]};
`;

const DisclaimerText = styled(Typography)`
  margin-top: ${theme.spacing['8x']};
`;

type Props = {
  isOpen?: boolean;
  customerFirstName: string;
  productLabel: string;
  productType: string;
  incis: string[];
  withDisclaimer?: boolean;
  onClose: ComponentProps<typeof BasicModal>['onClose'];
};

const InciListModal = ({
  isOpen = false,
  customerFirstName,
  productLabel,
  productType,
  incis,
  withDisclaimer = true,
  onClose,
}: Props) => (
  <BasicModal dataTestId="ingredients-modal-closer" isOpen={isOpen} onClose={onClose}>
    {/* @ts-expect-error - Typography component not typed */}
    <Label bold id={isOpen ? 'modal-title' : ''} variant="mono2">
      {`${genitive(customerFirstName)} ${productLabel} ingredients${withDisclaimer ? '*' : ''}`}
    </Label>

    {/* @ts-expect-error - Typography component not typed */}
    <InciList
      data-testid={`${productType}-incis-list`}
      id={isOpen ? 'modal-description' : ''}
      variant="p1"
    >
      {incis.join(', ')}
    </InciList>

    {withDisclaimer && (
      // @ts-expect-error - Typography component not typed
      <DisclaimerText
        color="grey"
        data-testid="incis-disclaimer"
        id={isOpen ? 'modal-condition' : ''}
        italic
        variant="p3"
      >
        *This is a close approximation of the ingredients in your custom formula. Because our
        products are made to order according to your needs and environment, your custom ingredients
        may vary slightly from what’s shown here. A finalized list of your product’s ingredients
        will be included in the leaflet that ships with your order.
      </DisclaimerText>
    )}
  </BasicModal>
);

export default InciListModal;
