import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { legacyTheme } from '@prose-ui/legacy';
import { makeStyles } from 'legacyStyles';

// inspired by the implementation of Material UI Typography component

const useStyles = makeStyles(
  (_theme, _props, classes) => ({
    root: {
      display: 'block',
      margin: 0,
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
      },
    },
    inline: {
      display: 'inline',
    },

    hero1: legacyTheme.typography.hero1,
    hero2: legacyTheme.typography.hero2,
    'hero-mono': legacyTheme.typography['hero-mono'],
    'h1r-mono': legacyTheme.typography['h1r-mono'],
    'h2r-mono': legacyTheme.typography['h2r-mono'],
    h1r: legacyTheme.typography.h1r,
    h2r: legacyTheme.typography.h2r,
    h1: legacyTheme.typography.h1,
    h2: legacyTheme.typography.h2,
    h3: legacyTheme.typography.h3,
    h4: legacyTheme.typography.h4,
    mono1: legacyTheme.typography.mono1,
    mono2: legacyTheme.typography.mono2,
    mono3: legacyTheme.typography.mono3,
    mono4: legacyTheme.typography.mono4,
    p1: legacyTheme.typography.p1,
    p2: legacyTheme.typography.p2,
    p3: legacyTheme.typography.p3,
    p4: legacyTheme.typography.p4,

    left: { textAlign: 'left' },
    center: { textAlign: 'center' },
    right: { textAlign: 'right' },
    justify: { textAlign: 'justify' },

    pre: { whiteSpace: 'pre' },
    nowrap: { whiteSpace: 'nowrap' },
    'pre-wrap': { whiteSpace: 'pre-wrap' },
    'pre-line': { whiteSpace: 'pre-line' },
    'break-spaces': { whiteSpace: 'break-spaces' },

    upperCase: { textTransform: 'uppercase' },
    italic: { fontStyle: 'italic' },
    underline: { textDecoration: 'underline' },

    // good to know: this one comes from the new palette
    lilasDark2: { color: legacyTheme.palette.common.lilas.dark2 },

    noir: { color: legacyTheme.palette.common.noir.medium },
    noirDark: { color: legacyTheme.palette.common.noir.dark },
    grey: { color: legacyTheme.palette.common.grey.dark },
    red: { color: 'red' }, // for error messages only
    rouge: { color: legacyTheme.palette.common.rouge.medium },
    sorbet: { color: legacyTheme.palette.common.sorbet.light },
    gold: { color: legacyTheme.palette.common.gold.medium },
    vert: { color: legacyTheme.palette.common.vert.medium },
    white: { color: legacyTheme.palette.common.white.light },
    lime: { color: legacyTheme.palette.common.lime.medium },
    lavender: { color: legacyTheme.palette.common.lavender.medium },

    bold: { fontWeight: 'bold' },
    normal: { fontWeight: 'normal' },
    medium: { fontWeight: 500 },

    paragraph: {
      marginBottom: '1em',
      [`&.${classes.hero1}`]: {
        marginBottom: '0.5em',
      },
      [`&.${classes.hero2}`]: {
        marginBottom: '0.5em',
      },
      [`&.${classes.h1r}`]: {
        marginBottom: '0.5em',
      },
      [`&.${classes.h2r}`]: {
        marginBottom: '0.5em',
      },
      [`&.${classes.h1}`]: {
        marginBottom: '0.5em',
      },
      [`&.${classes.h2}`]: {
        marginBottom: '0.5em',
      },
      [`&.${classes.h3}`]: {
        marginBottom: '0.5em',
      },
      [`&.${classes.h4}`]: {
        marginBottom: '0.5em',
      },
      [`&.${classes.mono1}`]: {
        marginBottom: '0.6em',
      },
      [`&.${classes.mono2}`]: {
        marginBottom: '0.6em',
      },
      [`&.${classes.mono3}`]: {
        marginBottom: '0.6em',
      },
      [`&.${classes.mono4}`]: {
        marginBottom: '0.6em',
      },
    },
  }),
  { name: 'Typography' }
);

const headlineMapping = {
  hero1: 'h1',
  hero2: 'h1',
  'hero-mono': 'h1',
  'h1r-mono': 'h1',
  'h2r-mono': 'h2',
  h1r: 'h1',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  mono1: 'p',
  mono2: 'p',
  mono3: 'p',
  mono4: 'p',
  p1: 'p',
  p2: 'p',
  p3: 'p',
  p4: 'p',
};

const Typography = forwardRef(
  (
    {
      align,
      bold,
      medium,
      children,
      className: classNameProp,
      color,
      inline,
      markupName,
      paragraph,
      variant,
      upperCase,
      italic,
      whiteSpace,
      underline,
      ...props
    },
    ref
  ) => {
    const { classes, cx } = useStyles(undefined, props?.classes ? { props } : undefined);
    const Component = markupName || headlineMapping[variant] || 'span';

    const className = cx(
      classes.root,
      { [classes.inline]: inline },
      { [classes[variant]]: variant !== 'inherit' },
      { [classes[align]]: align !== 'inherit' },
      { [classes[whiteSpace]]: whiteSpace !== 'normal' },
      { [classes.upperCase]: upperCase },
      { [classes.italic]: italic },
      { [classes.underline]: underline },
      { [classes[color]]: color !== 'inherit' },
      { [classes.bold]: bold },
      { [classes.normal]: !bold },
      { [classes.medium]: medium },
      { [classes.paragraph]: paragraph },
      classNameProp
    );

    return (
      <Component ref={ref} className={className} {...props}>
        {children}
      </Component>
    );
  }
);
Typography.displayName = 'Typography';

Typography.defaultProps = {
  align: 'inherit',
  bold: false,
  medium: false,
  classes: null,
  style: null,
  className: null,
  color: 'inherit',
  paragraph: false,
  variant: 'inherit',
  upperCase: false,
  italic: false,
  underline: false,
  inline: false,
  markupName: null,
  children: null, // Rendering null works fine
  whiteSpace: 'normal',
};

Typography.propTypes = {
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  whiteSpace: PropTypes.oneOf(['normal', 'pre', 'nowrap', 'pre-wrap', 'pre-line', 'break-spaces']),
  children: PropTypes.node, // Is called with nullish while loading
  className: PropTypes.string,
  bold: PropTypes.bool,
  medium: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  style: PropTypes.shape({}),
  color: PropTypes.oneOf([
    'inherit',
    'noir',
    'grey',
    'gold',
    'red',
    'rouge',
    'sorbet',
    'vert',
    'white',
    'lilasDark2',
    'noirDark',
    'lime',
    'lavender',
  ]),
  inline: PropTypes.bool,
  markupName: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.oneOf([
      'a',
      'b',
      'button',
      'figcaption',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'p',
      'div',
      'span',
      'label',
      'ul',
      'li',
      'legend',
      'strong',
    ]),
  ]),
  paragraph: PropTypes.bool,
  upperCase: PropTypes.bool,
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  variant: PropTypes.oneOf([
    'inherit',
    'hero1',
    'hero2',
    'hero-mono',
    'h1r-mono',
    'h2r-mono',
    'h1r',
    'h2r',
    'h1',
    'h2',
    'h3',
    'h4',
    'mono1',
    'mono2',
    'mono3',
    'mono4',
    'p1',
    'p2',
    'p3',
    'p4',
  ]),
};

export default Typography;
