import type { ReactNode } from 'react';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import Typography from 'Components/Typography';

import { ReactComponent as Cross } from 'assets/images/cross_icon.svg';

import useResponsiveVariant from 'utils/useResponsiveVariant';

import { BasicModal } from './BasicModal';
import Spacer from './Spacer';

const StyledModal = styled(BasicModal)`
  position: relative;

  width: 100%;
  margin-top: auto;
  padding-top: 56px;

  background-color: ${({ backgroundColor }) => backgroundColor};

  text-align: center;

  ${legacyTheme.breakpoints.match('sm')} {
    padding-top: 56px;
  }

  ${legacyTheme.breakpoints.up('sm')} {
    width: auto;
    min-width: 400px;
    margin-bottom: -24px;
  }

  ${legacyTheme.breakpoints.up('md')} {
    max-width: 450px;
    padding: 70px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const CloseModalButton = styled.button`
  position: absolute;
  top: 28px;
  right: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-top: -4px;
  padding: 0px;
  appearance: none;

  background: none;
  border: none;

  cursor: pointer;
`;

const Title = styled(Typography)`
  font-size: 14px;
  color: ${theme.colors.primary[400]};
`;

type BottomModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  content: {
    title: string | ReactNode;
    description: string | ReactNode;
    conditions?: string | ReactNode;
  };
  dataFrom?: string;
  backgroundColor?: string;
  testPrefix?: string;
};

export const BottomModal = ({
  isOpen = false,
  onClose,
  content,
  dataFrom,
  testPrefix,
  backgroundColor = theme.colors.tertiary[200],
}: BottomModalProps) => {
  const { isMobile } = useResponsiveVariant();

  return (
    <StyledModal
      backgroundColor={backgroundColor}
      dataFrom={dataFrom}
      hideClose
      isFullWidth={isMobile}
      isOpen={isOpen}
      onClose={onClose}
      zIndexValue={1501}
    >
      <CloseModalButton
        data-testid={`${testPrefix}-modal-close`}
        id="bottom-modal-close-button"
        onClick={onClose}
      >
        <Cross height="13" width="13" />
      </CloseModalButton>
      {/* @ts-ignore Legacy Typography  */}
      <Title
        aria-label={content?.title}
        data-testid={`${testPrefix}-modal-title`}
        id={isOpen ? 'modal-title' : ''}
        markupName="h2"
        medium
        variant="mono1"
      >
        {content?.title}
      </Title>
      <Spacer axis="vertical" size={16} />
      {/* @ts-ignore Legacy Typography */}
      <Typography
        data-testid={`${testPrefix}-modal-description`}
        id={isOpen ? 'modal-description' : ''}
        variant="p1"
      >
        {content?.description}
      </Typography>

      {content?.conditions && (
        <>
          <Spacer axis="vertical" size={16} />
          {/* @ts-expect-error Legacy Typography */}
          <Typography
            data-testid={`${testPrefix}-modal-conditions`}
            id={isOpen ? 'modal-conditions' : ''}
            variant="p3"
          >
            {content.conditions}
          </Typography>
        </>
      )}
    </StyledModal>
  );
};
