import type { ReactNode } from 'react';

import { legacyTheme, styled } from '@prose-ui/legacy';
import FocusTrap from 'focus-trap-react';
import Image from 'next/image';

import CrossIcon from 'assets/images/cross_icon.svg';

import ModalPortal from './ModalPortal';

const ModalBackdrop = styled.div<{ isFullWidth: boolean; zIndexValue: number }>`
  position: fixed;
  top: 0;
  left: 0;
  // @Nelly: The highest z-index known at that time is the checkout ticket at 1500 (not taking into 999999 index instances)
  z-index: ${({ zIndexValue }) => zIndexValue};
  // @Nelly: To be higher than the versions banner (src/Components/Version.js)

  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${({ isFullWidth }) => (isFullWidth ? 0 : legacyTheme.spacing.s24)};

  background-color: rgba(40, 51, 51, 0.6);
`;

const ModalBody = styled.div<{ backgroundColor: string; isFullWidth: boolean }>`
  position: relative;
  overflow: auto;

  max-width: ${({ isFullWidth }) => (isFullWidth ? 'none' : '650px')};
  max-height: calc(100vh - 56px);
  padding: ${legacyTheme.spacing.s36};
  padding-top: ${legacyTheme.spacing.s56};

  background-color: ${({ backgroundColor }) =>
    backgroundColor || legacyTheme.palette.backgrounds.white};

  ${legacyTheme.breakpoints.match('sm')} {
    max-width: ${({ isFullWidth }) => (isFullWidth ? 'none' : '360px')};
    max-height: calc(100vh - ${legacyTheme.spacing.s56});
    padding: ${legacyTheme.spacing.s24};
    padding-top: ${legacyTheme.spacing.s72};
  }
`;

const ModalBodyFullScreen = styled.div<{ backgroundColor: string }>`
  position: relative;
  overflow: auto;

  background-color: ${({ backgroundColor }) => backgroundColor};

  /**
   * Fallback for dvh:
   * the dynamic unit is supported on modern mobiles only
   * and a small percentage of our users are still on old devices
   */
  height: 100vh;
  @supports (max-height: 100dvh) {
    height: 100dvh;
  }
  padding: 0;
`;

const CloseModalButton = styled.button`
  position: absolute;
  top: ${legacyTheme.spacing.s24};
  right: ${legacyTheme.spacing.s24};

  width: ${legacyTheme.spacing.s24};
  height: ${legacyTheme.spacing.s24};
  margin: 0;
  padding: 0;

  appearance: none;
  background: none;
  border: none;

  cursor: pointer;
`;

type BasicModalProps = {
  className?: string;
  backgroundColor?: string;
  dataFrom?: string;
  dataClick?: string;
  children: ReactNode;
  isOpen: boolean;
  isFocusTrapActive?: boolean;
  isFullScreen?: boolean;
  onClose: () => void;
  dataTestId?: string;
  hideClose?: boolean;
  isFullWidth?: boolean;
  zIndexValue?: number;
};

export const BasicModal = ({
  backgroundColor = '',
  children,
  className = '',
  dataClick = 'close',
  dataFrom = 'modal',
  dataTestId = 'subscription-modal-closer',
  hideClose = false,
  isFocusTrapActive = true,
  isFullScreen = false,
  isFullWidth = false,
  isOpen,
  onClose,
  zIndexValue = 10000,
}: BasicModalProps) => {
  const ModalBodyComponent = isFullScreen ? ModalBodyFullScreen : ModalBody;

  return (
    <ModalPortal isOpen={isOpen}>
      <FocusTrap active={isFocusTrapActive}>
        <ModalBackdrop
          data-testid="subscription-modal-backdrop"
          isFullWidth={isFullWidth}
          onClick={onClose}
          onKeyDown={(e) => {
            if (e.which === 27 || e.keyCode === 27) {
              onClose();
            }
          }}
          role="dialog"
          zIndexValue={zIndexValue}
        >
          <ModalBodyComponent
            aria-describedby="modal-description modal-conditions"
            aria-labelledby="modal-title"
            backgroundColor={backgroundColor}
            className={className}
            isFullWidth={isFullWidth}
            onClick={(e) => {
              // This is necessary or clicking on the modal will close it
              e.stopPropagation();
            }}
            role="dialog"
          >
            {children}
            {!hideClose && (
              <CloseModalButton
                aria-label="close"
                data-click={dataClick}
                data-from={dataFrom}
                data-testid={dataTestId}
                id="modal-close-button"
                onClick={onClose}
                type="button"
              >
                <Image alt="close modal icon" height={24} src={CrossIcon} width={24} />
              </CloseModalButton>
            )}
          </ModalBodyComponent>
        </ModalBackdrop>
      </FocusTrap>
    </ModalPortal>
  );
};
