import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';

import { productsSlugs } from './products';

/**
 * Categories
 */

export const subscriptionCategories = /** @type {const} */ ({
  HAIRCARE: 'haircare',
  SKINCARE: 'skincare',
});
/** @typedef {(typeof subscriptionCategories)[keyof typeof subscriptionCategories]} SubscriptionCategory */

export const subscriptionSubCategories = /** @type {const} */ ({
  SUPPLEMENTS: 'supplements',
  TOPICALS: 'topicals',
});
/** @typedef {(typeof subscriptionSubCategories)[keyof typeof subscriptionSubCategories]} SubscriptionSubCategory */

/**
 * Due to the fact that Supplements is a subcategory of Haircare
 * And that we visually proposes Supplements as a category to the user
 * We need to consider Supplements as a category in some components
 */
export const subscriptionUXCategories = /** @type {const} */ ([
  subscriptionCategories.HAIRCARE,
  subscriptionCategories.SKINCARE,
  subscriptionSubCategories.SUPPLEMENTS,
]);
/** @typedef {(typeof subscriptionUXCategories)[number]} SubscriptionUXCategory */

/**
 * Coupons
 */

export const couponPercentage = 15;

/**
 * Frequencies
 */
export const defaultHaircareFrequency = 4;
const defaultSkincareFrequency = 4;
export const getDefaultFrequencyByCategory = category =>
  category === subscriptionCategories.SKINCARE
    ? defaultSkincareFrequency
    : defaultHaircareFrequency;

const frequenciesInWeeks = [
  { value: 4, label: 'Every 4 weeks', supplementLabel: 'Every 25 days' },
  { value: 8, label: 'Every 8 weeks', supplementLabel: 'Every 50 days' },
  { value: 12, label: 'Every 12 weeks', supplementLabel: 'Every 75 days' },
];
// Supplements frequency is disabled - Product decision
frequenciesInWeeks[productsSlugs.SUPPLEMENT_CORE] = [frequenciesInWeeks[0]];

const recommendedFrequenciesInWeeks = [
  {
    value: 4,
    label: 'Every 4 weeks (recommended)',
    supplementLabel: 'Every 25 days (recommended)',
  },
  {
    value: 8,
    label: 'Every 8 weeks (recommended)',
    supplementLabel: 'Every 50 days (recommended)',
  },
  {
    value: 12,
    label: 'Every 12 weeks (recommended)',
    supplementLabel: 'Every 75 days (recommended)',
  },
];

export const frequencyValues = frequenciesInWeeks.map(({ value }) => value);

export const getSubscriptionFrequencies = ({ isRecommended } = {}) =>
  isRecommended ? recommendedFrequenciesInWeeks : frequenciesInWeeks;

export const getFrequencyLabelForValue = ({
  value,
  category = subscriptionCategories.HAIRCARE,
  skincareRecommendedValue = null,
  isSupplements,
}) => {
  const recommendedValue =
    category === subscriptionCategories.SKINCARE
      ? skincareRecommendedValue || defaultSkincareFrequency
      : null;
  return flow(
    find({ value }),
    get(isSupplements ? 'supplementLabel' : 'label')
  )(
    getSubscriptionFrequencies({
      isRecommended: recommendedValue === value,
    })
  );
};

/**
 * Quantities
 */

export const quantities = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
];

export const supplementsQuantities = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
];

export const subscriptionModalQuantities = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
];
