import { type ComponentProps } from 'react';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import type { StaticImageData } from 'next/image';

import { BasicModal } from 'Components/BasicModal';
import Typography from 'Components/Typography';

const StyledBasicModal = styled(BasicModal)`
  padding-left: 0;
  padding-right: 0;
  text-align: center;
`;

const Title = styled(Typography)`
  margin-bottom: 18px;
`;

const BottleTitle = styled(Typography)`
  font-weight: 500;
  color: ${theme.colors.neutral[800]};
`;

const Image = styled.img`
  width: 100%;
  height: auto;

  ${legacyTheme.breakpoints.up('sm')} {
    width: 75%;
  }
`;

type Props = {
  isOpen: boolean;
  firstname: string;
  onClose: ComponentProps<typeof BasicModal>['onClose'];
  factsImages: { booster: StaticImageData; core: StaticImageData };
};

const SupplementsFactsModal = ({ onClose, firstname, isOpen, factsImages }: Props) => {
  return (
    <StyledBasicModal isOpen={isOpen} onClose={onClose}>
      {/* @ts-expect-error - Typography to be typed */}
      <Title id={isOpen ? 'modal-title' : ''} markupName="p" variant="p2">
        {firstname ? `${firstname}’s ` : 'Your '}Root Source<sup>®</sup> Hair Supplements
      </Title>

      <div id={isOpen ? 'modal-description' : ''}>
        {/* Bottle 1 */}
        {/* @ts-expect-error - Typography to be typed */}
        <BottleTitle markupName="p" variant="mono3">
          Bottle 1
        </BottleTitle>
        <Image
          alt="Supplements core facts - First jar"
          data-testid="supplements-core-facts-img"
          src={factsImages.core.src}
        />

        {/* Bottle 2 */}
        {/* @ts-expect-error - Typography to be typed */}
        <BottleTitle markupName="p" variant="mono3">
          Bottle 2
        </BottleTitle>
        <Image
          alt="Supplements softgel facts - Second jar"
          data-testid="supplements-softgel-facts-img"
          src={factsImages.booster.src}
        />
      </div>
    </StyledBasicModal>
  );
};

export default SupplementsFactsModal;
