import { useAppSelector } from 'dux/app/hooks';

import { couponCodes } from 'Services/CouponService';

import { feedbackSubCategories } from 'constants/feedback';

import { getIsAuthenticated } from 'dux/auth/selectors';
import {
  getCouponsStatuses,
  getIsLoading as getIsCouponsStatusLoading,
} from 'dux/couponsStatuses/selectors';
import {
  shouldShowEvergreenWelcomeKit,
  shouldShowSkincareMinisPhase2,
  shouldShowTrialOffer,
  shouldShowWelcomeKitVisibility,
} from 'dux/featureFlags/selectors';
import { getSupplementsLastOrderNumberOfMonthsSinceCreation } from 'dux/feedback/selectors';
import { hasOrderedSupplementsMultipleTimes as hasOrderedSupplementsMultipleTimesSelector } from 'dux/orders/selectors';
import { getHasActiveHaircareSubscription, getHasOrdersByCategory } from 'dux/user/selectors';
import { feedbackStatusesQuery } from 'dux/feedback/apiSlice';

type CouponsStatuses = {
  coupons?: Array<{
    code: string;
    is_attached_to_customer: boolean;
    status: string;
  }>;
};

const beforeEvergreenWelcomeKit = ({
  isAuthenticated,
  isCouponsStatusLoading,
  couponsStatuses,
  showWelcomeKitVisibility,
}: {
  isAuthenticated: boolean;
  isCouponsStatusLoading: boolean;
  couponsStatuses: CouponsStatuses;
  showWelcomeKitVisibility: boolean;
}) => {
  const isOfferAvailable = couponsStatuses?.coupons?.some(
    (c) =>
      c.code === couponCodes.skincareWelcomeKit &&
      c.is_attached_to_customer &&
      c.status === 'created',
  );
  if (showWelcomeKitVisibility && isAuthenticated && !isCouponsStatusLoading) {
    return isOfferAvailable;
  }
  return false;
};

const afterEvergreenWelcomeKit = ({
  showWelcomeKitVisibility,
  isAuthenticated,
  hasSkincareOrder,
  hasHaircareSubscription,
  isCouponsStatusLoading,
  couponsStatuses,
}: {
  showWelcomeKitVisibility: boolean;
  isAuthenticated: boolean;
  hasSkincareOrder: boolean;
  hasHaircareSubscription: boolean;
  isCouponsStatusLoading: boolean;
  couponsStatuses: CouponsStatuses;
}) => {
  const isOfferAvailable = couponsStatuses?.coupons?.some(
    (c) =>
      c.code === couponCodes.skincareWelcomeKit &&
      c.is_attached_to_customer &&
      c.status === 'created',
  );
  return (
    showWelcomeKitVisibility &&
    isAuthenticated &&
    hasHaircareSubscription &&
    !hasSkincareOrder &&
    !isCouponsStatusLoading &&
    isOfferAvailable
  );
};

export const PromoIdentifiers = {
  SKINCARE_WELCOME_KIT: 'skincare-welcome-kit',
  SKINCARE_MINIS: 'skincare-minis',
  TRIAL_OFFER: 'trial-offer',
  FEEDBACK: 'feedback',
  REFER_A_FRIEND: 'refer-a-friend',
} as const;
export type PromoIdentifiers = (typeof PromoIdentifiers)[keyof typeof PromoIdentifiers];

const promoConditions = {
  SKINCARE_WELCOME_KIT: {
    shouldShow: (promoContext: ReturnType<typeof usePromoContext>) =>
      promoContext.showEvergreenWelcomeKit
        ? afterEvergreenWelcomeKit(promoContext)
        : beforeEvergreenWelcomeKit(promoContext),
    identifier: PromoIdentifiers.SKINCARE_WELCOME_KIT,
  },
  SKINCARE_MINIS: {
    shouldShow: (promoContext: ReturnType<typeof usePromoContext>) =>
      promoContext.showSkincareMinisPhase2,
    identifier: PromoIdentifiers.SKINCARE_MINIS,
  },
  TRIAL_OFFER: {
    shouldShow: (promoContext: ReturnType<typeof usePromoContext>) => promoContext.showTrialOffer,
    identifier: PromoIdentifiers.TRIAL_OFFER,
  },
  FEEDBACK: {
    shouldShow: (promoContext: ReturnType<typeof usePromoContext>) => promoContext.showFeedback,
    identifier: PromoIdentifiers.FEEDBACK,
  },
} as const;

const usePromoContext = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  const isCouponsStatusLoading = useAppSelector(getIsCouponsStatusLoading);
  const couponsStatuses = useAppSelector(getCouponsStatuses);

  const hasHaircareSubscription = useAppSelector(getHasActiveHaircareSubscription);
  const hasSkincareOrder = useAppSelector((state) => getHasOrdersByCategory(state).skincare);

  /* @ts-expect-error - featureFlags selectors to be typed */
  const showWelcomeKitVisibility = useAppSelector(shouldShowWelcomeKitVisibility); // "exclusive" offer (for pre-selected customers)
  /* @ts-expect-error - featureFlags selectors to be typed */
  const showEvergreenWelcomeKit = useAppSelector(shouldShowEvergreenWelcomeKit); // "unrestricted" offer (for all customers)
  /* @ts-expect-error - featureFlags selectors to be typed */
  const showSkincareMinisPhase2 = useAppSelector(shouldShowSkincareMinisPhase2);
  const showTrialOffer = useAppSelector(shouldShowTrialOffer);

  const { data: feedbackStatusesData = [] } = feedbackStatusesQuery.useQuery();

  const hasOnlySupplementsFeedback =
    feedbackStatusesData?.length > 0
      ? feedbackStatusesData
          .filter((item) => item.status === 'created' || item.status === 'not_started')
          .every((item) => item.sub_category === feedbackSubCategories.SUPPLEMENTS)
      : false;

  const supplementsNumberOfMonthsSinceCreation = useAppSelector(
    getSupplementsLastOrderNumberOfMonthsSinceCreation,
  );
  const hasOrderedSupplementsMultipleTimes = useAppSelector(
    hasOrderedSupplementsMultipleTimesSelector,
  );

  const isReviewSupplementsAvailable =
    supplementsNumberOfMonthsSinceCreation >= 1 || hasOrderedSupplementsMultipleTimes;

  const showFeedback =
    feedbackStatusesData.length > 0 &&
    (!hasOnlySupplementsFeedback || (hasOnlySupplementsFeedback && isReviewSupplementsAvailable));

  return {
    isAuthenticated,
    isCouponsStatusLoading,
    couponsStatuses,
    hasHaircareSubscription,
    hasSkincareOrder,
    showWelcomeKitVisibility,
    showEvergreenWelcomeKit,
    showSkincareMinisPhase2,
    showTrialOffer,
    showFeedback,
  };
};

const priorityList = [
  promoConditions.SKINCARE_MINIS,
  promoConditions.SKINCARE_WELCOME_KIT,
  promoConditions.TRIAL_OFFER,
  promoConditions.FEEDBACK,
] as const;

export const useSkincareWelcomeKitOffer = () => {
  const promoContext = usePromoContext();
  return promoConditions.SKINCARE_WELCOME_KIT.shouldShow(promoContext);
};

/**
 * Returns a tuple of the possible identifers values and a list of promotions identifiers that should be shown
 */
export const usePromoIdentifers = () => {
  const promoContext = usePromoContext();
  const availablePromoIdentifierList = priorityList.flatMap((promo) => {
    if (promo.shouldShow(promoContext)) {
      return [promo.identifier];
    }
    return [];
  });

  return { PromoIdentifiers, availablePromoIdentifierList };
};
