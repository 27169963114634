/**
 * Import stripe as a side effect site wise to improve stripe's suspicious behavior detection capabalities
 * https://github.com/stripe/stripe-js#ensuring-stripejs-is-available-everywhere
 */
import '@stripe/stripe-js';
import 'what-input';

import '@prose-ui/legacy/legacyVars.css';

import { useEffect } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { useAppSelector } from 'dux/app/hooks';

import * as Sentry from '@sentry/nextjs';
import { useRedeemCouponForTrialOffer } from 'hooks/useRedeemCouponForTrialOffer';

import AccountApp from 'Apps/Account';
import CheckoutApp from 'Apps/Checkout';
import CartAccessoriesContainer from 'Apps/Checkout/Containers/CartAccessoriesContainer';
import CheckoutContainerV2 from 'Apps/Checkout/Containers/CheckoutContainerV2';
import ConsultationApp from 'Apps/Consultation';
import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';
import { CountryProvider } from 'Apps/CountryProvider';
import DigitalLeaflet from 'Apps/DigitalLeaflet/DigitalLeaflet';
import DigitalLeafletViaQRCode from 'Apps/DigitalLeaflet/DigitalLeafletViaQRCode';
import LastOrderDigital from 'Apps/DigitalLeaflet/LastOrderDigital';
import FeedbackApp from 'Apps/Feedback';
import GiftApp from 'Apps/Gifting';
import LandingPagesApp from 'Apps/LandingPages';
import MainApp from 'Apps/Main';
import InvalidReferralCode from 'Apps/Main/Scenes/InvalidReferralCode';
import MembershipApp from 'Apps/Membership';
import HaircareProductsApp from 'Apps/Products';
import PromotionTermsApp from 'Apps/PromotionTerms';
import SelfieApp from 'Apps/Selfie';

import Helmet from 'Components/Helmet';
import Leaflet from 'Components/Leaflets';
import RequireAuth from 'Components/RequireAuth';
import RootToast from 'Components/RootToast';
import ThemeProvider from 'Components/ThemeProvider';
import Variant from 'Components/Variant';
import Version from 'Components/Version';

import { useGrowthbookFakeExperimentAbTest } from 'abTesting/growthbookFakeExperiment/hook';
import { pruneABTestLocalStorage } from 'abTesting/utils';

import ErrorBoundary from 'Containers/ErrorBoundary';
import MagicAuth from 'Containers/MagicAuth';
import PageTracker from 'Containers/PageTracker';
import ProdItemLookUp from 'Containers/ProdItemLookUp';
import RedeemLanding from 'Containers/RedeemLanding';
import ScrollTopOnChange from 'Containers/ScrollTop';
import SigninContainer from 'Containers/SigninContainer';
import StylistLanding from 'Containers/StylistLanding';
import TriggerPageVisited from 'Containers/TriggerPageVisited';

import PromotionalLayout from 'layouts/PromotionalLayout';

import { stringToBool } from 'utils/envParsing';
import getEnv from 'utils/getEnv';
import RequireFeatureFlags from 'utils/RequireFeatureFlags';

import { shouldShowDigitalLeaflet, shouldShowUnlockAccessories } from 'dux/featureFlags/selectors';
import { useGetCartV2 } from 'dux/cartV2/hooks';
import { consultationRoutesActions } from 'dux/consultation/slice';
import { useFetchGuestAuthIfNeeded } from 'dux/guestAuth/hooks';

import { store } from './store';

try {
  pruneABTestLocalStorage();
} catch (error) {
  Sentry.captureException(error);
}

const AppsRouter = () => {
  const showUnlockAccessories = useAppSelector(shouldShowUnlockAccessories);

  const showDigitalLeaflet = useAppSelector(shouldShowDigitalLeaflet);
  useGrowthbookFakeExperimentAbTest({ isTrackingOnMount: true });

  useFetchGuestAuthIfNeeded();
  useGetCartV2();

  useRedeemCouponForTrialOffer();

  useEffect(() => {
    store.dispatch(
      consultationRoutesActions.setFirstHaircareConsultationQuestionRoute(
        FIRST_HAIRCARE_QUESTION_ROUTE
      )
    );
    store.dispatch(
      consultationRoutesActions.setFirstSkincareConsultationQuestionRoute(
        FIRST_SKINCARE_QUESTION_ROUTE
      )
    );
  }, []);

  return (
    <ThemeProvider>
      <ErrorBoundary>
        <Version
          initiallyVisible={stringToBool(getEnv('REACT_APP_SHOW_VERSION'))}
          versionAlgo={getEnv('REACT_APP_VERSION_ALGO')}
          versionApi={getEnv('REACT_APP_VERSION_API')}
          versionSite={getEnv('REACT_APP_VERSION_WWW')}
        />
        <RootToast />
        <Routes>
          <Route
            element={
              <>
                <PageTracker />
                <RequireAuth shouldRedirect={false}>
                  <TriggerPageVisited />
                </RequireAuth>
              </>
            }
            path="*"
          />
        </Routes>

        <Routes>
          {/*
          ScrollTop - uses react router to define when a scroll top should occour
          Normal behaviour is to scroll top after pathname changes
        */}
          <Route path="/checkout/:category">
            <Route path="payment" />
            <Route path="account-details" />
            <Route path="shipping-address" />
          </Route>
          <Route element={<ScrollTopOnChange />} path="*" />
        </Routes>
        <Routes>
          <Route
            element={<Helmet description="Truly Custom Haircare and Skincare | Prose" outlet />}
          >
            <Route element={<CountryProvider />}>
              {/* contains all app-wide promo modals or toasts */}
              <Route element={<PromotionalLayout />}>
                <Route element={<SigninContainer />} path="/signin" />
                <Route element={<MagicAuth />} path="/auth/signin" />
                <Route element={<SelfieApp />} path="/selfie/*" />
                <Route
                  element={
                    <RequireAuth>
                      <AccountApp />
                    </RequireAuth>
                  }
                  path="/account/*"
                />
                <Route path="/checkout/accessories">
                  <Route
                    element={
                      <RequireFeatureFlags
                        fallbackRender={() => <Navigate replace to="/" />}
                        shouldShow={showUnlockAccessories}
                      >
                        <CartAccessoriesContainer />
                      </RequireFeatureFlags>
                    }
                    index
                  />
                  <Route element={<CheckoutContainerV2 />} path="create-account" />
                  <Route element={<CheckoutContainerV2 />} path="account-details" />
                  <Route element={<CheckoutContainerV2 />} path="shipping-address" />
                  <Route element={<CheckoutContainerV2 />} path="payment" />
                  <Route element={<CheckoutContainerV2 />} path="confirmation" />
                </Route>
                <Route
                  element={
                    <RequireAuth>
                      <CheckoutApp />
                    </RequireAuth>
                  }
                  path="/checkout/*"
                />
                <Route element={<ConsultationApp />} path="/consultation/*" />
                <Route
                  element={
                    <RequireAuth>
                      <FeedbackApp />
                    </RequireAuth>
                  }
                  path="/feedback/*"
                />
                {/* Temporary redirections for old routes */}
                <Route
                  element={<Navigate replace to="/feedback/supplements" />}
                  path="/supplements-feedback"
                />
                <Route
                  element={<Navigate replace to="/feedback/supplements/what-to-expect" />}
                  path="/supplements-feedback/what-to-expect"
                />
                <Route element={<GiftApp />} path="/gift/*" />
                <Route element={<LandingPagesApp />} path="/pages/*" />
                <Route element={<Navigate replace to="/pages/oil" />} path="/oil" />
                <Route path="/v">
                  <Route element={<Variant />} path=":variantPubKey/*" />
                  <Route element={<Navigate replace to="/products/haircare" />} index />
                </Route>

                <Route element={<Helmet noIndex outlet />}>
                  <Route
                    element={
                      <RequireFeatureFlags
                        fallbackRender={() => <Leaflet />}
                        shouldShow={showDigitalLeaflet}
                      >
                        <DigitalLeafletViaQRCode />
                      </RequireFeatureFlags>
                    }
                    path="/l/:orderBoxPubKey/*"
                  />
                </Route>
                <Route element={<Helmet noIndex outlet />}>
                  <Route
                    element={
                      <RequireFeatureFlags
                        fallbackRender={() => <Navigate replace to="/" />}
                        shouldShow={showDigitalLeaflet}
                      >
                        <DigitalLeaflet />
                      </RequireFeatureFlags>
                    }
                    path="/digital-leaflet/:orderPubKey"
                  />
                  <Route
                    element={
                      <RequireAuth>
                        <RequireFeatureFlags
                          fallbackRender={() => <Navigate replace to="/" />}
                          shouldShow={showDigitalLeaflet}
                        >
                          <LastOrderDigital />
                        </RequireFeatureFlags>
                      </RequireAuth>
                    }
                    path="/digital-leaflet/last-order"
                  />
                </Route>

                <Route element={<Navigate replace to="/products/haircare" />} path="/f/*" />
                <Route element={<MembershipApp />} path="/the-salon/*" />
                <Route element={<HaircareProductsApp />} path="/products/*" />
                <Route element={<StylistLanding />} path="/stylist/:slug" />
                <Route element={<Helmet canonicalURL="/redeem" outlet />} path="/redeem">
                  <Route element={<InvalidReferralCode />} path="invalid-code" />
                  <Route element={<RedeemLanding />} index />
                  <Route element={<Navigate to="invalid-code" />} path="*" />
                </Route>
                <Route
                  element={
                    <RequireAuth>
                      <ProdItemLookUp />
                    </RequireAuth>
                  }
                  path="/p/:productionItemPubkey"
                />
                <Route element={<PromotionTermsApp />} path="/promotion-terms/*" />
                <Route element={<MainApp />} path="/*" />
              </Route>
            </Route>
          </Route>
        </Routes>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

AppsRouter.propTypes = {};

const Root = () => <AppsRouter />;

export default Root;
