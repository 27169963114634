import isBoolean from 'lodash/fp/isBoolean';
import isString from 'lodash/fp/isString';

import { stringToBoolOrValue } from 'utils/envParsing';
import getEnv from 'utils/getEnv';

import * as flagFetchStatus from './constants/flagFetchStatus';

// Basically we want to support variant default, to do so we check if this is a boolean or string in env variable
export const activeOrVariantStructureFromEnv = envVariable => {
  const value = stringToBoolOrValue(envVariable);
  if (isString(value)) {
    return { active: true, variant: value };
  }
  if (isBoolean(value)) {
    return { active: value };
  }
  return {};
};

export default () => ({
  flagFetchStatus: flagFetchStatus.STATIC,
  // Flags as we receive them from the back-end.
  flags: {
    'feedback-awareness-cta-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_FEEDBACK_AWARENESS_CTA_AB_TEST')
    ),
    'post-purchase-layout': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_POST_PURCHASE_LAYOUT')
    ),
    'skincare-minis-phase-2': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SKINCARE_MINIS_PHASE_2')
    ),
    'payment-intent': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_PAYMENT_INTENT')),
    'subscribe-save-checkout-module-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SUBSCRIBE_SAVE_CHECKOUT_MODULE_AB_TEST')
    ),
    'evergreen-welcome-kit': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_EVERGREEN_WELCOME_KIT')
    ),
    'address-validation': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_ADDRESS_VALIDATION')
    ),
    'ship-now-ab-test': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_SHIP_NOW_AB_TEST')),
    paypal: activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_PAYPAL')),
    'digital-leaflet': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_DIGITAL_LEAFLET')),
    'trial-offer': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_TRIAL_OFFER')),
    'promotion-accessories': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_PROMOTION_ACCESSORIES')
    ),
    'post-purchase-cross-sell-offer': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_POST_PURCHASE_CROSS_SELL_OFFER')
    ),
    'unlock-accessories': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_UNLOCK_ACCESSORIES')
    ),
    'growthbook-fake-experiment-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_GROWTHBOOK_FAKE_EXPERIMENT_AB_TEST')
    ),
    'prose-uk': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_PROSE_UK')),
    'welcome-kit-visibility': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_WELCOME_KIT_VISIBILITY')
    ),
    'subscription-snooze-button': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SUBSCRIPTION_SNOOZE_BUTTON')
    ),
    'account-cross-sell-banner-cta-destination-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_ACCOUNT_CROSS_SELL_BANNER_CTA_DESTINATION_AB_TEST')
    ),
    brightback: activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_BRIGHTBACK')),
    'friendbuy-v2': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_FRIENDBUY_V2')),
    'kustomer-livechat-at-cart': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_KUSTOMER_LIVECHAT_AT_CART')
    ),
    'kustomer-livechat-at-subscription': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_KUSTOMER_LIVECHAT_AT_SUBSCRIPTION')
    ),
    'express-checkout': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_EXPRESS_CHECKOUT')),
    brushes: activeOrVariantStructureFromEnv(getEnv('REACT_APP_SHOW_BRUSHES')),
  },
});
