import { subscriptionCategories, subscriptionSubCategories } from 'constants/subscriptions';

export const isSupplements = ({ category, subcategory = null }) =>
  subcategory === subscriptionSubCategories.SUPPLEMENTS ||
  category === subscriptionSubCategories.SUPPLEMENTS;

export const isTopicals = ({ category, subcategory = null }) =>
  subcategory === subscriptionSubCategories.TOPICALS ||
  category === subscriptionCategories.HAIRCARE ||
  category === subscriptionCategories.SKINCARE;

export const isHaircareTopicals = ({ category, subcategory = null }) =>
  (!subcategory && category === subscriptionCategories.HAIRCARE) ||
  (category === subscriptionCategories.HAIRCARE &&
    subcategory === subscriptionSubCategories.TOPICALS);

export const isSkincareTopicals = ({ category, subcategory = null }) =>
  (!subcategory && category === subscriptionCategories.SKINCARE) ||
  (category === subscriptionCategories.SKINCARE &&
    subcategory === subscriptionSubCategories.TOPICALS);

export const computeSubscriptionCategory = (
  /** @type {{category: string | undefined, subcategory: string | undefined}} */ {
    category,
    subcategory = null,
  }
) => {
  if (isHaircareTopicals({ category, subcategory })) {
    return subscriptionCategories.HAIRCARE;
  }
  if (isSupplements({ category, subcategory })) {
    return subscriptionSubCategories.SUPPLEMENTS;
  }
  // By default, skincare
  return category;
};
