import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAppDispatch as useDispatch } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

import Button from 'Components/LegacyButton';
import Typography from 'Components/Typography';

import BaseScene from 'Scenes/LegacyBaseScene';

import { trackHeapEvent } from 'dux/tracking/actions';
import { actions } from 'dux/signup/slice';

const StyledButtonSecondary = styled(Button)`
  border-color: ${props => props.theme.palette.common.noir.medium};

  color: ${props => props.theme.palette.common.noir.medium};

  :hover {
    border-color: ${props => props.theme.palette.common.white.light};
    background-color: ${theme.colors.neutral['700']};

    color: ${props => props.theme.palette.common.white.light};
  }
`;

const MagicLinkSent = ({ background, children, firstname }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackHeapEvent('MagicLinkSent Viewed'));
  }, []);

  const title = !firstname ? 'Check your email!' : `Nice to see you again ${firstname}!`;
  const desc = !firstname
    ? 'You just received a link to log you in.'
    : 'Check your email, you just received a link to log you in.';
  const ctaLabel = 'I did not receive a link';
  const ctaLink = '/consultation/haircare/signin?signin-first=true';

  const onClick = () => {
    dispatch(actions.setMagicLinkSent(false));
  };

  return (
    <BaseScene background={background} header>
      {children}
      <Typography align="center" paragraph variant="h1">
        {title}
      </Typography>

      <Typography align="center" paragraph variant="p2">
        {desc}
      </Typography>

      <a href={ctaLink}>
        {!firstname ? (
          <Button onClick={onClick} variant="underlined">
            {ctaLabel}
          </Button>
        ) : (
          <StyledButtonSecondary onClick={onClick} variant="noir">
            {ctaLabel}
          </StyledButtonSecondary>
        )}
      </a>
    </BaseScene>
  );
};

MagicLinkSent.propTypes = {
  background: PropTypes.string,
  /**
   * children is currently used for adding a progress tracker when MagicLinkSent
   * is called from the Consultation.
   */
  children: PropTypes.node,
  firstname: PropTypes.string,
};

MagicLinkSent.defaultProps = {
  background: 'none',
  children: null,
  firstname: '',
};

export default MagicLinkSent;
