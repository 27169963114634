import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import includes from 'lodash/fp/includes';
import isNil from 'lodash/fp/isNil';

import { createSelector } from 'reselect';

import { productsSlugs } from 'constants/products';

import * as userSelectors from 'dux/user/selectors';

import * as flagFetchStatuses from './constants/flagFetchStatus';
import * as flagVariants from './constants/flagVariants';
import initialState from './initialState';

// Get state.featureFlags
const getFeatureFlagsSubState = getOr(initialState, 'featureFlags');
// Get state.featureFlags.flags
export const getFlags = createSelector(getFeatureFlagsSubState, get('flags'));

// Get state.featureFlags.flagFetchStatus
const getFlagsFetchStatus = createSelector(getFeatureFlagsSubState, get('flagFetchStatus'));
// Get state.featureFlags.flagFetchStatus === USER_CONTEXT_SPECIFIC_FAIL || USER_CONTEXT_SPECIFIC_SUCCESS
export const isFlagsDoneFetching = createSelector(
  getFlagsFetchStatus,
  flagFetchStatus =>
    flagFetchStatus === flagFetchStatuses.USER_CONTEXT_SPECIFIC_FAIL ||
    flagFetchStatus === flagFetchStatuses.USER_CONTEXT_SPECIFIC_SUCCESS
);
// Get state.featureFlags.flagFetchStatus === USER_CONTEXT_SPECIFIC_PENDING
export const getIsFlagsLoading = createSelector(
  getFlagsFetchStatus,
  flagFetchStatus => flagFetchStatus === flagFetchStatuses.USER_CONTEXT_SPECIFIC_PENDING
);

// Get state.featureFlags.flags.express-checkout
const getExpressCheckoutFlag = createSelector(getFlags, get('express-checkout'));
// Get state.featureFlags.flags.express-checkout.active
const isExpressCheckoutFlagActive = createSelector(getExpressCheckoutFlag, get('active'));
// Get state.featureFlags.flags.express-checkout.variant
const getExpressCheckoutFlagVariant = createSelector(getExpressCheckoutFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForExpressCheckout = createSelector(
  isExpressCheckoutFlagActive,
  getExpressCheckoutFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowExpressCheckout = createSelector(
  getActiveOrVariantForExpressCheckout,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.kustomer-livechat-at-subscription
const getKustomerLivechatAtSubscriptionFlag = createSelector(
  getFlags,
  get('kustomer-livechat-at-subscription')
);
// Get state.featureFlags.flags.kustomer-livechat-at-subscription.active
const isKustomerLivechatAtSubscriptionFlagActive = createSelector(
  getKustomerLivechatAtSubscriptionFlag,
  get('active')
);
// Get state.featureFlags.flags.kustomer-livechat-at-subscription.variant
const getKustomerLivechatAtSubscriptionFlagVariant = createSelector(
  getKustomerLivechatAtSubscriptionFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForKustomerLivechatAtSubscription = createSelector(
  isKustomerLivechatAtSubscriptionFlagActive,
  getKustomerLivechatAtSubscriptionFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowKustomerLivechatAtSubscription = createSelector(
  getActiveOrVariantForKustomerLivechatAtSubscription,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.kustomer-livechat-at-cart
const getKustomerLivechatAtCartFlag = createSelector(getFlags, get('kustomer-livechat-at-cart'));
// Get state.featureFlags.flags.kustomer-livechat-at-cart.active
const isKustomerLivechatAtCartFlagActive = createSelector(
  getKustomerLivechatAtCartFlag,
  get('active')
);
// Get state.featureFlags.flags.kustomer-livechat-at-cart.variant
const getKustomerLivechatAtCartFlagVariant = createSelector(
  getKustomerLivechatAtCartFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForKustomerLivechatAtCart = createSelector(
  isKustomerLivechatAtCartFlagActive,
  getKustomerLivechatAtCartFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowKustomerLivechatAtCart = createSelector(
  getActiveOrVariantForKustomerLivechatAtCart,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.skincare-promotional-modal
const getSkincarePromotionalModalFlag = createSelector(getFlags, get('skincare-promotional-modal'));
// Get state.featureFlags.flags.skincare-promotional-modal.active
const isSkincarePromotionalModalFlagActive = createSelector(
  getSkincarePromotionalModalFlag,
  get('active')
);
// Get state.featureFlags.flags.skincare-promotional-modal.variant
const getSkincarePromotionalModalFlagVariant = createSelector(
  getSkincarePromotionalModalFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSkincarePromotionalModal = createSelector(
  isSkincarePromotionalModalFlagActive,
  getSkincarePromotionalModalFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSkincarePromotionalModal = createSelector(
  getActiveOrVariantForSkincarePromotionalModal,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
// Get state.featureFlags.flags.brightback
const getBrightbackFlag = createSelector(getFlags, get('brightback'));
// Get state.featureFlags.flags.brightback.active
const isBrightbackFlagActive = createSelector(getBrightbackFlag, get('active'));
// Get state.featureFlags.flags.brightback.variant
const getBrightbackFlagVariant = createSelector(getBrightbackFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForBrightback = createSelector(
  isBrightbackFlagActive,
  getBrightbackFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowBrightback = createSelector(
  getActiveOrVariantForBrightback,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.subscription-snooze-button
const getSubscriptionSnoozeButtonFlag = createSelector(getFlags, get('subscription-snooze-button'));
// Get state.featureFlags.flags.subscription-snooze-button.active
const isSubscriptionSnoozeButtonFlagActive = createSelector(
  getSubscriptionSnoozeButtonFlag,
  get('active')
);
// Get state.featureFlags.flags.subscription-snooze-button.variant
const getSubscriptionSnoozeButtonFlagVariant = createSelector(
  getSubscriptionSnoozeButtonFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSubscriptionSnoozeButton = createSelector(
  isSubscriptionSnoozeButtonFlagActive,
  getSubscriptionSnoozeButtonFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSubscriptionSnoozeButton = createSelector(
  getActiveOrVariantForSubscriptionSnoozeButton,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.welcome-kit-visibility
const getWelcomeKitVisibilityFlag = createSelector(getFlags, get('welcome-kit-visibility'));
// Get state.featureFlags.flags.welcome-kit-visibility.active
const isWelcomeKitVisibilityFlagActive = createSelector(getWelcomeKitVisibilityFlag, get('active'));
// Get state.featureFlags.flags.welcome-kit-visibility.variant
const getWelcomeKitVisibilityFlagVariant = createSelector(
  getWelcomeKitVisibilityFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForWelcomeKitVisibility = createSelector(
  isWelcomeKitVisibilityFlagActive,
  getWelcomeKitVisibilityFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowWelcomeKitVisibility = createSelector(
  getActiveOrVariantForWelcomeKitVisibility,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.growthbook-fake-experiment-ab-test
const getGrowthbookFakeExperimentAbTestFlag = createSelector(
  getFlags,
  get('growthbook-fake-experiment-ab-test')
);
// Get state.featureFlags.flags.growthbook-fake-experiment-ab-test.active
const isGrowthbookFakeExperimentAbTestFlagActive = createSelector(
  getGrowthbookFakeExperimentAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.growthbook-fake-experiment-ab-test.variant
const getGrowthbookFakeExperimentAbTestFlagVariant = createSelector(
  getGrowthbookFakeExperimentAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForGrowthbookFakeExperimentAbTest = createSelector(
  isGrowthbookFakeExperimentAbTestFlagActive,
  getGrowthbookFakeExperimentAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isGrowthbookFakeExperimentAbTestInExperiment = createSelector(
  getActiveOrVariantForGrowthbookFakeExperimentAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);
// Get state.featureFlags.flags.prose-uk
const getProseUkFlag = createSelector(getFlags, get('prose-uk'));
// Get state.featureFlags.flags.prose-uk.active
const isProseUkFlagActive = createSelector(getProseUkFlag, get('active'));
// Get state.featureFlags.flags.prose-uk.variant
const getProseUkFlagVariant = createSelector(getProseUkFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForProseUk = createSelector(
  isProseUkFlagActive,
  getProseUkFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);

// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowProseUk = createSelector(getActiveOrVariantForProseUk, activeOrVariant =>
  activeOrVariant === 'on' || activeOrVariant === 'off' ? activeOrVariant === 'on' : activeOrVariant
);
// Get state.featureFlags.flags.unlock-accessories
const getUnlockAccessoriesFlag = createSelector(getFlags, get('unlock-accessories'));
// Get state.featureFlags.flags.unlock-accessories.active
const isUnlockAccessoriesFlagActive = createSelector(getUnlockAccessoriesFlag, get('active'));
// Get state.featureFlags.flags.unlock-accessories.variant
const getUnlockAccessoriesFlagVariant = createSelector(getUnlockAccessoriesFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForUnlockAccessories = createSelector(
  isUnlockAccessoriesFlagActive,
  getUnlockAccessoriesFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowUnlockAccessories = createSelector(
  getActiveOrVariantForUnlockAccessories,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
// Get state.featureFlags.flags.post-purchase-cross-sell-offer
const getPostPurchaseCrossSellOfferFlag = createSelector(
  getFlags,
  get('post-purchase-cross-sell-offer')
);
// Get state.featureFlags.flags.post-purchase-cross-sell-offer.active
const isPostPurchaseCrossSellOfferFlagActive = createSelector(
  getPostPurchaseCrossSellOfferFlag,
  get('active')
);
// Get state.featureFlags.flags.post-purchase-cross-sell-offer.variant
const getPostPurchaseCrossSellOfferFlagVariant = createSelector(
  getPostPurchaseCrossSellOfferFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
export const getActiveOrVariantForPostPurchaseCrossSellOffer = createSelector(
  isPostPurchaseCrossSellOfferFlagActive,
  getPostPurchaseCrossSellOfferFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowPostPurchaseCrossSellOffer = createSelector(
  getActiveOrVariantForPostPurchaseCrossSellOffer,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.promotion-accessories
const getPromotionAccessoriesFlag = createSelector(getFlags, get('promotion-accessories'));
// Get state.featureFlags.flags.promotion-accessories.active
const isPromotionAccessoriesFlagActive = createSelector(getPromotionAccessoriesFlag, get('active'));
// Get state.featureFlags.flags.promotion-accessories.variant
const getPromotionAccessoriesFlagVariant = createSelector(
  getPromotionAccessoriesFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForPromotionAccessories = createSelector(
  isPromotionAccessoriesFlagActive,
  getPromotionAccessoriesFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowPromotionAccessories = createSelector(
  getActiveOrVariantForPromotionAccessories,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
export const shouldShowPromotionAccessoriesforTargetedUser = createSelector(
  shouldShowPromotionAccessories,
  userSelectors.getHasActiveSubscription,
  (promotionFFActive, hasSubscription) => {
    return promotionFFActive && hasSubscription;
  }
);
export const shouldShowPromotionAccessoriesforTargetedUserBySlug = createSelector(
  shouldShowPromotionAccessoriesforTargetedUser,
  (_state, payload) => payload?.slug,
  (showPromotionAccessoriesforTargetedUser, slug) =>
    showPromotionAccessoriesforTargetedUser &&
    [productsSlugs.BRUSH, productsSlugs.CANDLE, productsSlugs.HAIR_TOWEL_WRAP].includes(slug)
);

// Get state.featureFlags.flags.digital-leaflet
const getDigitalLeafletFlag = createSelector(getFlags, get('digital-leaflet'));
// Get state.featureFlags.flags.digital-leaflet.active
const isDigitalLeafletFlagActive = createSelector(getDigitalLeafletFlag, get('active'));
// Get state.featureFlags.flags.digital-leaflet.variant
const getDigitalLeafletFlagVariant = createSelector(getDigitalLeafletFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForDigitalLeaflet = createSelector(
  isDigitalLeafletFlagActive,
  getDigitalLeafletFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowDigitalLeaflet = createSelector(
  getActiveOrVariantForDigitalLeaflet,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.trial-offer
const getTrialOfferFlag = createSelector(getFlags, get('trial-offer'));
// Declared here to avoid dependency cycle
export const trialOfferEvergreen = 'evergreen';
export const trialOfferCombGwp = 'comb-gwp';
export const trialOfferGwpMystery = 'mystery-gwp';
export const trialOfferToiletryGwp = 'toiletry-gwp';
export const trialOfferGwpJuly4th = 'july-fourth-gwp';
export const trialOfferGwpSummerSale = 'summer-sale-gwp';

const enabledVariants = [
  trialOfferEvergreen,
  trialOfferCombGwp,
  trialOfferGwpMystery,
  trialOfferToiletryGwp,
  trialOfferGwpJuly4th,
  trialOfferGwpSummerSale,
];
// Get state.featureFlags.flags.trial-offer.active
const isTrialOfferFlagActive = createSelector(getTrialOfferFlag, get('active'));
// Get state.featureFlags.flags.trial-offer.variant
export const getTrialOfferFlagVariant = createSelector(getTrialOfferFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForTrialOffer = createSelector(
  isTrialOfferFlagActive,
  getTrialOfferFlagVariant,
  (active, variant) => active && !isNil(variant) && enabledVariants.includes(variant) && variant
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowTrialOffer = createSelector(
  getActiveOrVariantForTrialOffer,
  userSelectors.getHasHaircareSubscriptionInAnyState,
  userSelectors.getHasSkincareSubscriptionInAnyState,
  (activeOrVariant, hasHaircareSubscription, hasSkincareSubscription) =>
    activeOrVariant === 'off' || (hasHaircareSubscription && hasSkincareSubscription)
      ? false
      : activeOrVariant
);

// Get state.featureFlags.flags.paypal
const getPaypalFlag = createSelector(getFlags, get('paypal'));
// Get state.featureFlags.flags.paypal.active
const isPaypalFlagActive = createSelector(getPaypalFlag, get('active'));
// Get state.featureFlags.flags.paypal.variant
const getPaypalFlagVariant = createSelector(getPaypalFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForPaypal = createSelector(
  isPaypalFlagActive,
  getPaypalFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowPaypal = createSelector(getActiveOrVariantForPaypal, activeOrVariant =>
  activeOrVariant === 'on' || activeOrVariant === 'off' ? activeOrVariant === 'on' : activeOrVariant
);

// Get state.featureFlags.flags.ship-now-ab-test
const getShipNowAbTestFlag = createSelector(getFlags, get('ship-now-ab-test'));
// Get state.featureFlags.flags.ship-now-ab-test.active
const isShipNowAbTestFlagActive = createSelector(getShipNowAbTestFlag, get('active'));
// Get state.featureFlags.flags.ship-now-ab-test.variant
const getShipNowAbTestFlagVariant = createSelector(getShipNowAbTestFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForShipNowAbTest = createSelector(
  isShipNowAbTestFlagActive,
  getShipNowAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isShipNowAbTestInExperiment = createSelector(
  getActiveOrVariantForShipNowAbTest,
  userSelectors.getHasActiveSubscription,
  (activeOrVariant, hasActiveSubscription) =>
    hasActiveSubscription &&
    includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);

// Get state.featureFlags.flags.evergreen-welcome-kit
const getEvergreenWelcomeKitFlag = createSelector(getFlags, get('evergreen-welcome-kit'));
// Get state.featureFlags.flags.evergreen-welcome-kit.active
const isEvergreenWelcomeKitFlagActive = createSelector(getEvergreenWelcomeKitFlag, get('active'));
// Get state.featureFlags.flags.evergreen-welcome-kit.variant
const getEvergreenWelcomeKitFlagVariant = createSelector(
  getEvergreenWelcomeKitFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForEvergreenWelcomeKit = createSelector(
  isEvergreenWelcomeKitFlagActive,
  getEvergreenWelcomeKitFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowEvergreenWelcomeKit = createSelector(
  getActiveOrVariantForEvergreenWelcomeKit,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.address-validation
const getAddressValidationFlag = createSelector(getFlags, get('address-validation'));
// Get state.featureFlags.flags.address-validation.active
const isAddressValidationFlagActive = createSelector(getAddressValidationFlag, get('active'));
// Get state.featureFlags.flags.address-validation.variant
const getAddressValidationFlagVariant = createSelector(getAddressValidationFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForAddressValidation = createSelector(
  isAddressValidationFlagActive,
  getAddressValidationFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowAddressValidation = createSelector(
  getActiveOrVariantForAddressValidation,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.subscribe-save-checkout-module-ab-test
const getSubscribeSaveCheckoutModuleAbTestFlag = createSelector(
  getFlags,
  get('subscribe-save-checkout-module-ab-test')
);
// Get state.featureFlags.flags.subscribe-save-checkout-module-ab-test.active
const isSubscribeSaveCheckoutModuleAbTestFlagActive = createSelector(
  getSubscribeSaveCheckoutModuleAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.subscribe-save-checkout-module-ab-test.variant
const getSubscribeSaveCheckoutModuleAbTestFlagVariant = createSelector(
  getSubscribeSaveCheckoutModuleAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForSubscribeSaveCheckoutModuleAbTest = createSelector(
  isSubscribeSaveCheckoutModuleAbTestFlagActive,
  getSubscribeSaveCheckoutModuleAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isSubscribeSaveCheckoutModuleAbTestInExperiment = createSelector(
  getActiveOrVariantForSubscribeSaveCheckoutModuleAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);
// Get state.featureFlags.flags.payment-intent
const getPaymentIntentFlag = createSelector(getFlags, get('payment-intent'));
// Get state.featureFlags.flags.payment-intent.active
const isPaymentIntentFlagActive = createSelector(getPaymentIntentFlag, get('active'));
// Get state.featureFlags.flags.payment-intent.variant
const getPaymentIntentFlagVariant = createSelector(getPaymentIntentFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForPaymentIntent = createSelector(
  isPaymentIntentFlagActive,
  getPaymentIntentFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowPaymentIntent = createSelector(
  getActiveOrVariantForPaymentIntent,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
// Get state.featureFlags.flags.skincare-minis-phase-2
const getSkincareMinisPhase2Flag = createSelector(getFlags, get('skincare-minis-phase-2'));
// Get state.featureFlags.flags.skincare-minis-phase-2.active
const isSkincareMinisPhase2FlagActive = createSelector(getSkincareMinisPhase2Flag, get('active'));
// Get state.featureFlags.flags.skincare-minis-phase-2.variant
const getSkincareMinisPhase2FlagVariant = createSelector(
  getSkincareMinisPhase2Flag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSkincareMinisPhase2 = createSelector(
  isSkincareMinisPhase2FlagActive,
  getSkincareMinisPhase2FlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSkincareMinisPhase2 = createSelector(
  getActiveOrVariantForSkincareMinisPhase2,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.feedback-awareness-cta-ab-test
const getFeedbackAwarenessCtaAbTestFlag = createSelector(
  getFlags,
  get('feedback-awareness-cta-ab-test')
);
// Get state.featureFlags.flags.feedback-awareness-cta-ab-test.active
const isFeedbackAwarenessCtaAbTestFlagActive = createSelector(
  getFeedbackAwarenessCtaAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.feedback-awareness-cta-ab-test.variant
const getFeedbackAwarenessCtaAbTestFlagVariant = createSelector(
  getFeedbackAwarenessCtaAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForFeedbackAwarenessCtaAbTest = createSelector(
  isFeedbackAwarenessCtaAbTestFlagActive,
  getFeedbackAwarenessCtaAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isFeedbackAwarenessCtaAbTestInExperiment = createSelector(
  getActiveOrVariantForFeedbackAwarenessCtaAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);
// Get state.featureFlags.flags.post-purchase-layout
const getPostPurchaseLayoutFlag = createSelector(getFlags, get('post-purchase-layout'));
// Get state.featureFlags.flags.post-purchase-layout.active
const isPostPurchaseLayoutFlagActive = createSelector(getPostPurchaseLayoutFlag, get('active'));
// Get state.featureFlags.flags.post-purchase-layout.variant
const getPostPurchaseLayoutFlagVariant = createSelector(getPostPurchaseLayoutFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForPostPurchaseLayout = createSelector(
  isPostPurchaseLayoutFlagActive,
  getPostPurchaseLayoutFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowPostPurchaseLayout = createSelector(
  getActiveOrVariantForPostPurchaseLayout,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
