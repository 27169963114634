import { useState } from 'react';
import PropTypes from 'prop-types';

import { useAppSelector } from 'dux/app/hooks';

import { Box, theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import Image from 'next/image';

import CartOrderSummary from 'Apps/Checkout/Blocks/CartOrderSummary';
import MembershipCommunication from 'Apps/Checkout/Blocks/MembershipCheckoutCommunication';
import CartMobileFooter from 'Apps/Checkout/Components/CartMobileFooter';

import { BasicModal } from 'Components/BasicModal';
import Spacer from 'Components/Spacer';
import Typography from 'Components/Typography';

import { mobileToolbarHeight } from 'constants/mobile';
import { productsCategories } from 'constants/products';

import skincareMinisContent from 'assets/content/skincareMinisModal';
import close from 'assets/images/icon_close.svg';

import useResponsiveVariant from 'utils/useResponsiveVariant';

import { getHasSkincareMinisInCatalog } from 'dux/checkoutCart/selectors';
import { getUserCurrency } from 'dux/user/selectors';

import useCheckoutCategory from '../hooks/useCheckoutCategory';
import CartPromoCommunication from './CartPromoCommunication';

const CheckoutTicketContainer = styled.div`
  z-index: 9999;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;

  background-color: ${theme.colors.neutral[100]};

  ${legacyTheme.breakpoints.up('sm')} {
    z-index: 0;

    padding: ${legacyTheme.props.minimumBlockPadding};
    padding-left: 0;

    background-color: transparent;
  }
`;

const StyledModal = styled(BasicModal)`
  position: relative;

  width: 100%;
  padding-top: 0;

  background-color: ${theme.colors.neutral[100]};

  text-align: center;
  display: flex;
  flex-direction: column;
`;

const TicketHeader = styled.div`
  position: sticky;
  padding-top: 30px;
`;

const TicketCloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  border: none;
  background-color: transparent;
  font: inherit;
  cursor: pointer;
  ${legacyTheme.breakpoints.up('sm')} {
    display: none;
  }
`;

const TicketBody = styled.div`
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  padding: 0 32px;
  margin-bottom: auto;
  height: 100%;
  /**
   * Fallback for dvh:
   * the dynamic unit is supported on modern mobiles only
   * and a small percentage of our users are still on old devices
   */
  max-height: calc(100vh - ${mobileToolbarHeight}px - 52.5px - 33px - 326.5px);
  // Viewport - modal header - modal header border - cart promo block
  @supports (max-height: 100dvh) {
    max-height: calc(100dvh - 52.5px - 33px - 326.5px);
  }

  text-align: left;

  /**
   * Approximately 1 item in the list,
   * In case the user disabled the scrollbar showing by default we are afraid they will miss that
   * they are able to scroll. So we re-implement the scrollbar completely in a similar style.
   */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    &:vertical {
      width: 11px;
    }
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-track {
    background-color: ${theme.colors.neutral['100']};
    border-radius: 8px;
  }

  ${legacyTheme.breakpoints.up('sm')} {
    max-height: none;
    margin-bottom: 0;

    box-shadow: unset;
  }
`;

const TicketContainer = styled.div`
  ${legacyTheme.breakpoints.up('sm')} {
    display: flex;
    flex-direction: column;
    width: 100%;

    background-color: ${theme.colors.neutral[100]};
    box-shadow: 2px 2px 3px 0 ${theme.colors.neutral[600]};
  }
`;

const TicketBorder = styled.hr`
  margin: 16px 0;
  border: none;
  border-top: 1px solid ${theme.colors.neutral[600]};

  ${legacyTheme.breakpoints.up('sm')} {
    margin: 24px 32px;
  }
`;

const MembershipCommunicationMobileWrapper = styled(MembershipCommunication)`
  border-radius: 0px;
  max-width: none;
`;

const SkincareMinisCommunicationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-width: 310px;
  margin: 0 auto;
  padding: 65px 55px;
  background-color: ${theme.colors.highlight[200]};
`;

const CheckoutTicketV2 = ({ ctaButtonProps, wantsPumps, isCheckout }) => {
  const { isMobile } = useResponsiveVariant();
  const [showCart, setShowCart] = useState(false);
  const currency = useAppSelector(getUserCurrency);

  const checkoutCategory = useCheckoutCategory();

  // On mobile the ticket is displayed within a modal - on desktop the ticket is next to the cart items
  const TicketWrapperComponent = isMobile ? StyledModal : TicketContainer;
  const hideOrderSummary = isCheckout && isMobile;
  const hasSkincareMinisInCatalog = useAppSelector(getHasSkincareMinisInCatalog);

  return (
    <CheckoutTicketContainer data-testid="checkout-ticket-v2">
      {!hideOrderSummary && (
        <TicketWrapperComponent
          hideClose
          isFullScreen
          isFullWidth
          isOpen={showCart}
          onClose={() => setShowCart(false)}
        >
          <TicketHeader>
            <Typography align="center" bold id={showCart ? 'modal-title' : ''} variant="mono1">
              Order Summary
            </Typography>
            <TicketCloseButton
              data-click="order-summary-close"
              data-from="cart"
              data-testid="cart-order-summary-drawer-close-button"
              id="checkout-ticket-modal-close-button"
              onClick={() => setShowCart(false)}
              type="button"
            >
              <Image alt="Close icon - vector" height={12} src={close} width={12} />
            </TicketCloseButton>
          </TicketHeader>

          <TicketBorder />

          <TicketBody id={showCart ? 'modal-description' : ''}>
            <CartOrderSummary
              ctaButtonProps={ctaButtonProps}
              isCheckout={isCheckout}
              wantsPumps={wantsPumps}
            />
          </TicketBody>

          {checkoutCategory !== productsCategories.ACCESSORIES &&
            !isCheckout &&
            isMobile &&
            (hasSkincareMinisInCatalog ? (
              <SkincareMinisCommunicationContainer>
                <Box marginBottom="4x">
                  <Typography variant="mono3">
                    {skincareMinisContent.bottomModal[currency].title}
                  </Typography>
                </Box>
                {skincareMinisContent.bottomModal[currency].description}
              </SkincareMinisCommunicationContainer>
            ) : (
              <MembershipCommunicationMobileWrapper id={showCart ? 'modal-conditions' : ''} />
            ))}
        </TicketWrapperComponent>
      )}

      {!isCheckout && (
        <>
          {checkoutCategory !== productsCategories.ACCESSORIES && (
            <>
              <CartPromoCommunication />
              {!isMobile && (
                <>
                  <Spacer size={14} />
                  <MembershipCommunication borderRadiusPosition="bottom" />
                </>
              )}
            </>
          )}
          {isMobile && (
            <CartMobileFooter
              ctaButtonProps={ctaButtonProps}
              onToggleCart={() => setShowCart(true)}
            />
          )}
        </>
      )}
    </CheckoutTicketContainer>
  );
};

CheckoutTicketV2.propTypes = {
  ctaButtonProps: PropTypes.shape({
    text: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    variant: PropTypes.string,
  }),
  wantsPumps: PropTypes.bool,
  isCheckout: PropTypes.bool,
};

CheckoutTicketV2.defaultProps = {
  ctaButtonProps: {},
  wantsPumps: true,
  isCheckout: false,
};

export default CheckoutTicketV2;
