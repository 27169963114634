import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import image from 'assets/images/promotionalModals/trial-offer-comb-gwp-promotion-modal.jpg';

const content = {
  contentDefault: {
    banner: 'Get 50% off haircare or try skincare for free',
    popUpModal: {
      image,
      headerTitle: 'Summer sale',
      title: 'Try Prose with 50% off + a free comb',
      description:
        'Unlock 50% off + your free gift when you subscribe to 3+ hair or skin products.',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock the deal',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock the deal',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-sc-pop-up',
      },
      conditions:
        'Get [1] free hair comb when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 08/25/2024 at 11:59 PM EST or while supplies last. Comb selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'Trial Offer v2 HC + SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC + SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free comb</b> when you subscribe to 3+ hair or skin products.
        </>
      ),
      conditions:
        'Get [1] free hair comb when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 08/25/2024 at 11:59 PM EST or while supplies last. Comb selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free comb</b> when you subscribe to 3+ hair or skin products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    banner: 'Try custom haircare with 50% off + a free gift',
    popUpModal: {
      image,
      headerTitle: 'Summer sale',
      title: 'Try custom haircare with 50% off + a free comb',
      description: 'Unlock 50% off + your free gift when you subscribe to 3+ hair products.',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock the deal',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock the deal',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-pop-up',
      },
      conditions:
        'Get [1] free hair comb when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 08/25/2024 at 11:59 PM EST or while supplies last. Comb selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'Trial Offer v2 HC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free comb</b> when you subscribe to 3+ hair products.
        </>
      ),
      conditions:
        'Get [1] free hair comb when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 08/25/2024 at 11:59 PM EST or while supplies last. Comb selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free comb</b> when you subscribe to 3+ hair products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'Try custom skincare with 50% off + a free gift',
    popUpModal: {
      image,
      headerTitle: 'Summer sale',
      title: 'Try custom skincare with 50% off + a free comb',
      description: 'Unlock 50% off + your free gift when you subscribe to 3+ skin products.',
      cta: {
        consultationCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock the deal',
        },
        consultationNotCompleted: {
          linkTo: () => FIRST_SKINCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock the deal',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'sc-pop-up',
      },
      conditions:
        'Get [1] free hair comb when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 08/25/2024 at 11:59 PM EST or while supplies last. Comb selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'Trial Offer v2 SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free comb</b> when you subscribe to 3+ skincare products.
        </>
      ),
      conditions:
        'Get [1] free hair comb when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 08/25/2024 at 11:59 PM EST or while supplies last. Comb selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free comb</b> when you subscribe to 3+ skincare products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
};

export default content;
